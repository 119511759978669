import ApiService from '@/services/api.service';
import MockUp     from './mockNotifications';

export const NOTIFICATIONS_FETCH_STATE        = 'NOTIFICATIONS_FETCH_STATE';
export const NOTIFICATIONS_CLEAR_ID           = 'NOTIFICATIONS_CLEAR_ID';
export const NOTIFICATIONS_CLEAR_POPUP_ID     = 'NOTIFICATIONS_CLEAR_POPUP_ID';
export const NOTIFICATIONS_SET_STATE          = 'NOTIFICATIONS_SET_STATE';
export const NOTIFICATIONS_SET_STATE_LOADING  = 'NOTIFICATIONS_SET_STATE_LOADING';
export const NOTIFICATIONS_SET_STATE_ERROR    = 'NOTIFICATIONS_SET_STATE_ERROR';

const initialState = {
  notifications: {
    state: [],
    loading: true,
    error: null,
  },
};

const state = initialState;

const getters = {
  loadingNotifications ( state ) {
    return state.notifications.loading;
  },

  notifications ( state ) {
    // Just for testing.
    // return MockUp.alerts;
    return state.notifications.state ? state.notifications.state.alerts : [];
  },

  unseenNotifications ( state, getters ) {
    return getters.notifications
      ? getters.notifications.filter( data => !data.seen )
      : []
  },

  unseenNotifCount ( state, getters ) {
    // Just for testing.
    // return String( MockUp.unseenAlerts );
    return state.notifications.state && state.notifications.state.unseenAlerts;
  }
};

const actions = {
  [NOTIFICATIONS_FETCH_STATE] ( context, { isClient, hideSpinner } ) {
    const cID = context.getters.communityId;
    const endpoint = isClient
      ? `/experienceLabs/client/communities/${ cID }/alerts/page/0/pageSize/100`
      : `/experienceLabs/notifications/${ cID }/alerts/page/0/pageSize/100`;

    if (!hideSpinner) {
      context.commit( NOTIFICATIONS_SET_STATE_LOADING );
    }

    return ApiService
      .get( endpoint )
      .then( ( { data } ) => {
        context.commit( NOTIFICATIONS_SET_STATE, data );
        return data;
      })
      .catch( ( { response } ) => {
        context.commit( NOTIFICATIONS_SET_STATE_ERROR, response );
        return response;
      } );
  },

  [NOTIFICATIONS_CLEAR_ID] ( context, payload ) {
    const { ids, isClient, hideSpinner } = payload;
    const endpoint = isClient
      ? '/experienceLabs/client/notifications/seen'
      : '/experienceLabs/notifications/seen';

    return ApiService
      .post( endpoint, { "idsToSee": ids } )
      .then( async ( { data } ) => {
        await context.dispatch( NOTIFICATIONS_FETCH_STATE, { isClient, hideSpinner } );
        return data;
      })
      .catch( ( { response } ) => {
        context.commit( NOTIFICATIONS_SET_STATE_ERROR, response );
        return response;
      } );
  },
  [NOTIFICATIONS_CLEAR_POPUP_ID] ( context, payload ) {
    const { ids } = payload;
    const endpoint ='/experienceLabs/notifications/seenPopup';

    return ApiService
      .post( endpoint, { "idsToSee": ids } )
      .then( async ( { data } ) => {
        await context.dispatch( NOTIFICATIONS_FETCH_STATE, { isClient: false } );
        return data;
      })
      .catch( ( { response } ) => {
        context.commit( NOTIFICATIONS_SET_STATE_ERROR, response );
        return response;
      } );
  },
};

const mutations = {
  [NOTIFICATIONS_SET_STATE] ( state, payload ) {
    state.notifications.state = payload;
    state.notifications.loading = false;
    state.notifications.error = null;
  },

  [NOTIFICATIONS_SET_STATE_LOADING] ( state ) {
    state.notifications.loading = true;
  },

  [NOTIFICATIONS_SET_STATE_ERROR] ( state, error ) {
    state.notifications.error = error;
    state.notifications.loading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};