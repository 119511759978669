import ApiService from '@/services/api.service';
import { find } from 'lodash';

export const COMMUNITY_ADD_ACTIVITY = 'COMMUNITY_ADD_ACTIVITY';
export const COMMUNITY_ADD_ACTIVITY_QUESTIONS = 'COMMUNITY_ADD_ACTIVITY_QUESTIONS';
export const COMMUNITY_DELETE_ACTIVITY_QUESTION = 'COMMUNITY_DELETE_ACTIVITY_QUESTION';
export const COMMUNITY_UPDATE_ACTIVITY_QUESTIONS = 'COMMUNITY_UPDATE_ACTIVITY_QUESTIONS';
export const COMMUNITY_ADD_ACTIVITY_QUESTIONS_RESPONSE = 'COMMUNITY_ADD_ACTIVITY_QUESTIONS_RESPONSE';
export const COMMUNITY_FETCH_ACTIVITY_QUESTION_ANSWERED = 'COMMUNITY_FETCH_ACTIVITY_QUESTION_ANSWERED';
export const COMMUNITY_UPDATE_ACTIVITY = 'COMMUNITY_UPDATE_ACTIVITY';
export const COMMUNITY_FETCH_ACTIVITIES = 'COMMUNITY_FETCH_ACTIVITIES';
export const COMMUNITY_FETCH_ACTIVITY = 'COMMUNITY_FETCH_ACTIVITY';
export const COMMUNITY_FETCH_ACTIVITY_COMMENTS = 'COMMUNITY_FETCH_ACTIVITY_COMMENTS';
export const COMMUNITY_FETCH_ACTIVITY_PINNED_COMMENTS = 'COMMUNITY_FETCH_ACTIVITY_PINNED_COMMENTS';
export const COMMUNITY_ADD_ACTIVITY_COMMENT = 'COMMUNITY_ADD_ACTIVITY_COMMENT';
export const COMMUNITY_ADD_ACTIVITY_COMMENT_REPLY = 'COMMUNITY_ADD_ACTIVITY_COMMENT_REPLY';
export const COMMUNITY_DELETE_ACTIVITY_COMMENT = 'COMMUNITY_DELETE_ACTIVITY_COMMENT';
export const COMMUNITY_ADD_ACTIVITY_COMMENT_REACTION = 'COMMUNITY_ADD_ACTIVITY_COMMENT_REACTION';
export const COMMUNITY_UPDATE_ACTIVITY_COMMENT_TAGS = 'COMMUNITY_UPDATE_ACTIVITY_COMMENT_TAGS';
export const COMMUNITY_ADD_ACTIVITY_COMMENT_MEDAL = 'COMMUNITY_ADD_ACTIVITY_COMMENT_MEDAL';
export const COMMUNITY_DELETE_ACTIVITY = 'COMMUNITY_DELETE_ACTIVITY';
export const COMMUNITY_FINISH_ACTIVITY = 'COMMUNITY_FINISH_ACTIVITY';
export const COMMUNITY_DUPLICATE_ACTIVITY = 'COMMUNITY_DUPLICATE_ACTIVITY';
export const COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS = 'COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS';
export const COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS_EL = 'COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS_EL';
export const COMMUNITY_REMIND_CURRENT_ACTIVITY_PARTICIPANT = 'COMMUNITY_REMIND_CURRENT_ACTIVITY_PARTICIPANT';
export const COMMUNITY_REMIND_CURRENT_ACTIVITY_ALL_PENDING_PARTICIPANTS = 'COMMUNITY_REMIND_CURRENT_ACTIVITY_ALL_PENDING_PARTICIPANTS';
export const COMMUNITY_FETCH_ACTIVITY_RESULTS_COMMENTS_PARTICIPANTS = 'COMMUNITY_FETCH_ACTIVITY_RESULTS_COMMENTS_PARTICIPANTS';
export const COMMUNITY_CHANGE_CURRENT_ACTIVITY = 'COMMUNITY_CHANGE_CURRENT_ACTIVITY';
export const COMMUNITY_FETCH_ACTIVITY_RESULTS = 'COMMUNITY_FETCH_ACTIVITY_RESULTS';
export const COMMUNITY_FETCH_ACTIVITY_QUESTION_RESULT = 'COMMUNITY_FETCH_ACTIVITY_QUESTION_RESULT';
export const COMMUNITY_FETCH_ACTIVITY_EXCEL = 'COMMUNITY_FETCH_ACTIVITY_EXCEL';
export const COMMUNITY_FETCH_ACTIVITY_WORD = 'COMMUNITY_FETCH_ACTIVITY_WORD';
export const COMMUNITY_FETCH_ACTIVITY_MULTIMEDIA = 'COMMUNITY_FETCH_ACTIVITY_MULTIMEDIA';
export const COMMUNITY_SET_ACTIVITIES_UNSEEN = 'COMMUNITY_SET_ACTIVITIES_UNSEEN';
export const COMMUNITY_SET_ACTIVITIES = 'COMMUNITY_SET_ACTIVITIES';
export const COMMUNITY_SET_ACTIVITIES_LOADING = 'COMMUNITY_SET_ACTIVITIES_LOADING';
export const COMMUNITY_SET_ACTIVITIES_ERROR = 'COMMUNITY_SET_ACTIVITIES_ERROR';
export const COMMUNITY_SET_ACTIVITIES_DEFAULT = 'COMMUNITY_SET_ACTIVITIES_DEFAULT';
export const COMMUNITY_SET_ACTIVITY_COMMENTS = 'COMMUNITY_SET_ACTIVITY_COMMENTS';
export const COMMUNITY_SET_ACTIVITY_COMMENTS_LOADING = 'COMMUNITY_SET_ACTIVITY_COMMENTS_LOADING';
export const COMMUNITY_SET_ACTIVITY_COMMENTS_ERROR = 'COMMUNITY_SET_ACTIVITY_COMMENTS_ERROR';
export const COMMUNITY_SET_CURRENT_ACTIVITY = 'COMMUNITY_SET_CURRENT_ACTIVITY';
export const COMMUNITY_PURGE_CURRENT_ACTIVITY = 'COMMUNITY_PURGE_CURRENT_ACTIVITY';
export const COMMUNITY_SET_CURRENT_ACTIVITY_PARTICIPANTS = 'COMMUNITY_SET_CURRENT_ACTIVITY_PARTICIPANTS';
export const COMMUNITY_SET_CURRENT_ACTIVITY_PARTICIPANTS_LOADING = 'COMMUNITY_SET_CURRENT_ACTIVITY_PARTICIPANTS_LOADING';
export const COMMUNITY_SET_CURRENT_ACTIVITY_PARTICIPANTS_ERROR = 'COMMUNITY_SET_CURRENT_ACTIVITY_PARTICIPANTS_ERROR';
export const COMMUNITY_PIN_COMMENT = 'COMMUNITY_PIN_COMMENT';
export const COMMUNITY_FETCH_COMMENTS_SEARCH_TOPIC = 'COMMUNITY_FETCH_COMMENTS_SEARCH_TOPIC';
export const COMMUNITY_FETCH_COMMENTS_SEARCH = 'COMMUNITY_FETCH_COMMENTS_SEARCH';
export const COMMUNITY_FETCH_COMMENTS_SEARCH_V2 = 'COMMUNITY_FETCH_COMMENTS_SEARCH_V2';
export const COMMUNITY_FETCH_COMMENTS_SEARCH_WORD = 'COMMUNITY_FETCH_COMMENTS_SEARCH_WORD';
export const COMMUNITY_SET_UNSEEN_COMMENTS = 'COMMUNITY_SET_UNSEEN_COMMENTS';
export const COMMUNITY_FETCH_ALLOWED_ACTIVITIES = 'COMMUNITY_FETCH_ALLOWED_ACTIVITIES'
export const COMMUNITY_FETCH_ACTIVITY_USER_COMMENT_RECOMENDATION = 'FETCH_ACTIVITY_USER_COMMENT_RECOMENDATION'
export const COMMUNITY_FETCH_ACTIVITY_UNSEEN_MENTION_PER_PAGE = 'COMMUNITY_FETCH_ACTIVITY_UNSEEN_MENTION_PER_PAGE'

export const ACTIVITY_STEP_1 = 'ACTIVITY_STEP_1'
export const ACTIVITY_STEP_2_TOPICS = 'ACTIVITY_STEP_2_TOPICS'
export const ACTIVITY_STEP_2_HEATMAP = 'ACTIVITY_STEP_2_HEATMAP'
export const ACTIVITY_STEP_2_SURVEY = 'ACTIVITY_STEP_2_SURVEY'
export const ACTIVITY_STEP_2_WATUX = 'ACTIVITY_STEP_2_WATUX'
export const ACTIVITY_STEP_3 = 'ACTIVITY_STEP_3'
export const ACTIVITY_NAVIGATION_RULES = 'ACTIVITY_NAVIGATION_RULES'

export const ACTIVITY_FETCH_ANALYSIS_DATA_COMPLETE = 'ACTIVITY_FETCH_ANALYSIS_DATA_COMPLETE'
export const ACTIVITY_FETCH_ANALYSIS_DATA = 'ACTIVITY_FETCH_ANALYSIS_DATA'
export const ACTIVITY_FETCH_ANALYSIS_BY_TOPIC = 'ACTIVITY_FETCH_ANALYSIS_BY_TOPIC'
export const ACTIVITY_ADD_MODERATOR_TOPICS = 'ACTIVITY_ADD_MODERATOR_TOPICS'
export const ACTIVITY_FETCH_MODERATOR_TOPICS = 'ACTIVITY_FETCH_MODERATOR_TOPICS'
export const COMMUNITY_ACTIVITY_SET_ANALYSIS_DATA = 'COMMUNITY_ACTIVITY_SET_ANALYSIS_DATA'
export const ACTIVITY_DELETE_MODERATOR_TOPICS = 'ACTIVITY_DELETE_MODERATOR_TOPICS'
export const ACTIVITY_RENEW_ANALYSIS_CHANGE_SUMMARY = 'ACTIVITY_RENEW_ANALYSIS_CHANGE_SUMMARY'

export const ACTIVITY_SET_RAFFLE_NOTIFICATED = 'ACTIVITY_SET_RAFFLE_NOTIFICATED'

export const CALL_VIDEO_BACKEND_URL = 'CALL_VIDEO_BACKEND_URL'

const initialState = {
    activities: {
        state: [],
        loading: false,
        error: null,
    },
    activitiesClient: {
        state: [],
        loading: false,
        error: null,
    },
    currentActivity: {
        id: null,
        participants: {
            state: [],
            loading: false,
            error: null,
        },
        comments: {
            state: {},
            loading: false,
            error: null,
        },
        activityData: {
            state: [],
            loading: false,
            error: null,
        },
        forceUpdateDefaultActivity: false
    },
    activityAnalysis:{
        AIsummary: '',
        AIsummaryLong: '',
        comments: [],
        topics: [],
        AIsummaryIsLong: false
    }
};

const state = initialState;

const getters = {

    communityActivitiesUnseen(state) {
        return state.activitiesClient.state.totalModeratorUnseen
    },

    communityActivities(state) {
        return state.activities.state;
    },

    communityCurrentActivity(state, getters) {
        return state.currentActivity.activityData
    },

    communityCurrentActivityId(state) {
        return state.currentActivity.id;
    },

    communityCurrentActivityParticipants(state) {
        return state.currentActivity.participants.state;
    },

    communityCurrentActivityComments(state) {
        return state.currentActivity.comments.state.objects;
    },

    communityCurrentActivityCommentsPage(state) {
        return state.currentActivity.comments.state.page + 1;
    },

    communityCurrentActivityTotalCommentsPage(state) {
        return state.currentActivity.comments.state.pages;
    },

    currentActivityCommentsErrorStatus(state) {
        return state.currentActivity.comments.error && state.currentActivity.comments.error.status;
    },

    forceUpdateDefaultActivity(state) {
        return state.forceUpdateDefaultActivity
    },
    activityAnalysis(state){
        return state.activityAnalysis
    }
};

const actions = {
    [COMMUNITY_ADD_ACTIVITY_QUESTIONS](context, payload) {
        return ApiService
            .post('/client/communities/' + context.getters.communityId + '/activity/' + payload.activityId + '/questions', { communityQuestions: payload.data })
            .then(({ data }) => {
                return data
            })
            .catch(({ response }) => response);
    },

    [COMMUNITY_UPDATE_ACTIVITY_QUESTIONS](context, payload) {
        return ApiService
            .put('/client/communities/' + context.getters.communityId + '/activity/' + context.getters.communityCurrentActivityId + '/questions', { communityQuestions: payload.data })
            .then(({ data }) => {
                return data
            })
            .catch(({ response }) => response);
    },

    [COMMUNITY_DELETE_ACTIVITY_QUESTION](context, payload) {
        return ApiService
            .delete('/client/communities/' + context.getters.communityId + '/activity/' + context.getters.communityCurrentActivityId + '/question/' + payload.questionId)
            .then(({ data }) => {
                return data
            })
            .catch(({ response }) => response);
    },

    [COMMUNITY_ADD_ACTIVITY_QUESTIONS_RESPONSE](context, payload) {
        return ApiService
            .post('/tester/communities/' + context.getters.communityId + '/activity/' + payload.activityId + '/answers', { communityAnswers: payload.data })
            .then(({ data }) => {
                return data
            })
            .catch(({ response }) => response);
    },

    [COMMUNITY_FETCH_ACTIVITY_QUESTION_ANSWERED](context, payload) {
        return ApiService
            .get('/tester/communities/' + context.getters.communityId + '/activity/' + payload.activityId + '/question/' + payload.questionId + '/answered')
            .then(({ data }) => {
                return data
            })
            .catch(({ response }) => {
                return response
            })
    },

    [COMMUNITY_ADD_ACTIVITY](context, payload) {
        return ApiService
            .post('/client/communities/' + context.getters.communityId + '/activity', payload.data)
            .then(({ data }) => {
                return data
            })
            .catch(({ response }) => response);
    },

    [COMMUNITY_UPDATE_ACTIVITY](context, payload) {
        return ApiService
            .put('/client/communities/' + context.getters.communityId + '/activity/' + payload.activityId, payload.data)
            .then(({ data }) => {
                return data
            })
            .catch(({ response }) => response);
    },


    [COMMUNITY_FETCH_ACTIVITY](context, payload) {
        return ApiService
            .get((context.getters.isClient ? 'client' : 'tester') + '/communities/' + context.getters.communityId + '/activity/' + payload.activityId)
            .then(({ data }) => {
                context.commit(COMMUNITY_PURGE_CURRENT_ACTIVITY);
                context.commit(COMMUNITY_SET_CURRENT_ACTIVITY, data);
                return data;
            })
            .catch(({ response }) => response);
    },

    [COMMUNITY_FETCH_ACTIVITY_COMMENTS](context, payload) {
        const { activityId, page, noSave } = payload;

        context.commit(COMMUNITY_SET_ACTIVITY_COMMENTS_LOADING);

        return ApiService
            .get((context.getters.isClient ? 'client' : 'tester') + '/communities/' + context.getters.communityId + '/activity/' + activityId + '/comments/page/' + page + '/size/10')
            .then(({ data }) => {
                if (noSave == null || noSave == undefined || noSave == false) context.commit(COMMUNITY_SET_ACTIVITY_COMMENTS, data);
                return data;
            })
            .catch(({ response }) => {
                context.commit(COMMUNITY_SET_ACTIVITY_COMMENTS_ERROR, response);

                return response;
            });
    },

    [COMMUNITY_FETCH_ACTIVITY_PINNED_COMMENTS](context, payload) {
        const { activityId } = payload;

        context.commit(COMMUNITY_SET_ACTIVITY_COMMENTS_LOADING);

        return ApiService
            .get((context.getters.isClient ? 'client' : 'tester') + '/communities/' + context.getters.communityId + '/activity/' + activityId + '/pinnedComments')
            .then(({ data }) => {
                return data;
            })
            .catch(({ response }) => {
                return response;
            });
    },

    [COMMUNITY_FETCH_ACTIVITY_RESULTS](context, payload) {
        return ApiService
            .get('/experienceLabs/community/' + context.getters.communityId + '/activity/' + payload.activityId + '/results')
            .then(({ data }) => data)
            .catch(({ response }) => Promise.reject(response));
    },

    [COMMUNITY_FETCH_ACTIVITY_QUESTION_RESULT](context, payload) {
        return ApiService
            .get('/' + (context.getters.isClient ? 'client' : 'tester') + '/communities/' + context.getters.communityId + '/activity/' + context.getters.communityCurrentActivityId + '/question/' + payload.questionId + '/report')
            .then(({ data }) => data)
            .catch(({ response }) => response);
    },

    [COMMUNITY_FETCH_ACTIVITY_EXCEL](context, payload) {
        return ApiService
            .get('/client/communities/' + context.getters.communityId + '/activity/' + payload.activityId + '/excel', null, {
                headers: {
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'authToken': ApiService.getConfig().token()
                },
                responseType: 'blob',
            })
            .then(({ data }) => data)
            .catch(({ response }) => response);
    },

    [COMMUNITY_FETCH_ACTIVITY_WORD](context, payload) {
        return ApiService
            .get('/experienceLabs/client/' + context.getters.communityId + '/activity/' + payload.activityId + '/word', null, {
                headers: {
                    'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'authToken': ApiService.getConfig().token()
                },
                responseType: 'blob',
            })
            .then(({ data }) => data)
            .catch(({ response }) => response);
    },

    [COMMUNITY_FETCH_ACTIVITY_MULTIMEDIA](context, payload) {
        return ApiService
            .get('/experienceLabs/communities/' + context.getters.communityId + '/activities/' + payload.activityId + '/multimedia', null, {
                headers: {
                    'Accept': 'application/zip',
                    'authToken': ApiService.getConfig().token()
                },
                responseType: 'blob',
            })
            .then(({ data }) => data)
            .catch(({ response }) => response);
    },

    [COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS_EL](context, payload) {
        const { order, filter, publicFilter, name, nickname, activityId, page, pageSize } = payload;

        let query = {
            order,
        };

        if (filter) {
            query = {
                ...query,
                filter,
            };
        }

        if (publicFilter) {
            query = {
                ...query,
                publicFilter,
            };
        }

        if (nickname) {
            query = {
                ...query,
                nickname,
            };
        }
        if (page) {
            query = {
                ...query,
                page,
            };
        }
        if (pageSize) {
            query = {
                ...query,
                pageSize,
            };
        }
        return ApiService
            .get('/experienceLabs/' + (context.getters.isClient ? 'client' : 'tester') + '/communities/' + context.getters.communityId + '/activity/' + activityId + '/participants', query)
            .then(({ data }) => {
                for (let item of data.communityMembers) {
                    item.online = false
                }
                return data;
            })
            .catch(({ response }) => {
                return response;
            });
    },

    [COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS](context, payload) {
        const { activityId } = payload;

        context.commit(COMMUNITY_SET_CURRENT_ACTIVITY_PARTICIPANTS_LOADING);

        return ApiService
            .get((context.getters.isClient ? 'client' : 'tester') + '/communities/' + context.getters.communityId + '/activity/' + activityId + '/participants')
            .then(({ data }) => {
                context.commit(COMMUNITY_SET_CURRENT_ACTIVITY_PARTICIPANTS, data.communityMembers);

                return data;
            })
            .catch(({ response }) => {
                context.commit(COMMUNITY_SET_CURRENT_ACTIVITY_PARTICIPANTS_ERROR, response);

                return response;
            });
    },

    [COMMUNITY_FETCH_ACTIVITY_RESULTS_COMMENTS_PARTICIPANTS](context, payload) {
        return ApiService
            .get('/experienceLabs/client/communities/' + context.getters.communityId + '/activity/' + payload.activityId + '/comments/participants')
            .then(({ data }) => {
                return data;
            })
            .catch(({ response }) => {
                return response;
            })
    },

    [COMMUNITY_REMIND_CURRENT_ACTIVITY_PARTICIPANT](context, payload) {
        return ApiService
            .post('/experienceLabs/client/communities/' + context.getters.communityId + '/activity/' + payload.activityId + '/member/' + payload.memberId + '/remind')
            .then(({ data }) => {
                return data;
            })
            .catch(({ response }) => {
                return response;
            });
    },

    [COMMUNITY_REMIND_CURRENT_ACTIVITY_ALL_PENDING_PARTICIPANTS](context, payload) {
        return ApiService
            .post('/experienceLabs/client/communities/' + context.getters.communityId + '/activity/' + payload.activityId + '/allPendingMembers/remind')
            .then(({ data }) => {
                return data;
            })
            .catch(({ response }) => {
                return response;
            });
    },

    [COMMUNITY_ADD_ACTIVITY_COMMENT](context, payload) {
        const { activityId, text, images, videos, files, isRootComment, topicId } = payload;

        const data = {
            text,
            images,
            videos,
            files,
            isRootComment,
            topicId
        };

        return ApiService
            .post((context.getters.isClient ? 'client' : 'tester') + '/communities/' + context.getters.communityId + '/activity/' + activityId + '/comment', data)
            .then(({ data }) => data)
            .catch(({ response }) => response);
    },

    [COMMUNITY_ADD_ACTIVITY_COMMENT_REPLY](context, payload) {
        const { activityId, parentCommentId, text, images, videos, files, pdf } = payload;

        const data = {
            parentCommentId,
            text,
            images,
            videos,
            files,
            pdf,
        };

        return ApiService
            .post((context.getters.isClient ? 'client' : 'tester') + '/communities/' + context.getters.communityId + '/activity/' + activityId + '/comment', data)
            .then(({ data }) => data)
            .catch(({ response }) => response);
    },

    [COMMUNITY_DELETE_ACTIVITY_COMMENT](context, payload) {
        return ApiService
            .delete('/client/communities/comment/' + payload.commentId)
            .then(({ data }) => data)
            .catch(({ response }) => response);
    },

    [COMMUNITY_ADD_ACTIVITY_COMMENT_REACTION](context, payload) {
        const { activityId, commentId, reactionType } = payload

        return ApiService
            .put((context.getters.isClient ? 'client' : 'tester') + '/communities/' + context.getters.communityId + '/activity/' + activityId + '/comment/' + commentId, { reactionType })
            .then(({ data }) => data)
            .catch(({ response }) => response);
    },

    [COMMUNITY_UPDATE_ACTIVITY_COMMENT_TAGS](context, payload) {
        const { activityId, commentId, tags } = payload;

        return ApiService
            .put('/client/communities/' + context.getters.communityId + '/activity/' + activityId + '/comment/' + commentId, tags)
            .then(({ data }) => data)
            .catch(({ response }) => response);
    },

    [COMMUNITY_ADD_ACTIVITY_COMMENT_MEDAL](context, payload) {
        return ApiService
            .post('/experienceLabs/comment/' + payload.commentId + '/medal', { medal: payload.medal })
            .then(({ data }) => data)
            .catch(({ response }) => response);
    },

    [COMMUNITY_DUPLICATE_ACTIVITY](context, payload) {
        return ApiService
            .post('/client/communities/' + context.getters.communityId + '/activity/' + payload.activityId + '/duplicate')
            .then(({ data }) => data)
            .catch(({ response }) => response);
    },

    [COMMUNITY_DELETE_ACTIVITY](context, payload) {
        return ApiService
            .delete('/client/communities/' + context.getters.communityId + '/activity/' + payload.activityId)
            .then(({ data }) => data)
            .catch(({ response }) => response);
    },

    [COMMUNITY_FINISH_ACTIVITY](context, payload) {
        return ApiService
            .post('/client/communities/' + context.getters.communityId + '/activity/' + payload.activityId + '/finish')
            .then(({ data }) => data)
            .catch(({ response }) => response);
    },

    [COMMUNITY_PIN_COMMENT](context, payload) {
        const { activityId, commentId } = payload;
        const { communityId } = context.getters;

        return ApiService.put(
            `/client/communities/${communityId}/activity/${activityId}/comment/${commentId}`,
            payload.data
        ).then(({ data }) => data).catch(({ response }) => response);
    },
    [COMMUNITY_FETCH_COMMENTS_SEARCH_TOPIC](context, payload) {
        const { activityId, topicId, page, pageSize } = payload
        return ApiService
            .get('/client/communities/' + context.getters.communityId + '/activity/' + activityId + '/comments/topic/' + topicId + '/page/' + page + '/size/' + pageSize)
            .then(({ data }) => {
                return data
            })
            .catch(({ response }) => response);
    },
    [COMMUNITY_FETCH_COMMENTS_SEARCH](context, payload) {
        const { searchTxt, activityId, userId, page, pageSize } = payload
        let data = {
            searchTxt: searchTxt,
            userId: userId,
            activityId: activityId,
        }

        return ApiService
            .post('/experienceLabs/client/communities/' + context.getters.communityId + '/commentSearch?page=' + page + '&pageSize=' + pageSize, data)
            .then(({ data }) => {
                return data
            })
            .catch(({ response }) => response);
    },
    [COMMUNITY_FETCH_COMMENTS_SEARCH_V2](context, payload) {
        const { data, page, pageSize } = payload
        return ApiService
            .post('/experienceLabs/client/communities/' + context.getters.communityId + '/commentSearch?page=' + page + '&pageSize=' + pageSize, data)
            .then(({ data }) => {
                return data
            })
            .catch(({ response }) => response);
    },
    [COMMUNITY_FETCH_COMMENTS_SEARCH_WORD](context, payload) {
        const { searchTxt, activityId, userId, page, pageSize } = payload
        let data = {
            searchTxt: searchTxt,
            userId: userId,
            activityId: activityId,
        }

        return ApiService
            .post('/experienceLabs/client/communities/' + context.getters.communityId + '/searchWord?page=' + page + '&pageSize=' + pageSize, data, {
                headers: {
                    'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'authToken': ApiService.getConfig().token()
                },
                responseType: 'blob',
            })
            .then(({ data }) => {
                return data
            })
            .catch(({ response }) => response);
    },
    [COMMUNITY_FETCH_ACTIVITIES](context, payload) {
        const { priority } = payload

        context.commit(COMMUNITY_SET_ACTIVITIES_LOADING);
        return ApiService
            .get((context.getters.isClient ? '/client' : '/tester') + '/communities/' + context.getters.communityId + '/' + priority)
            .then(({ data }) => {
                let activities = []
                activities.push.apply(activities, context.getters.isClient ? data.activities : data)
                activities = activities.map(function (a) {
                    a.showActions = false
                    return a;
                })
                context.commit(COMMUNITY_SET_ACTIVITIES, activities);
                context.commit(COMMUNITY_SET_ACTIVITIES_UNSEEN, data);

                return data;
            })
            .catch(({ response }) => {
                context.commit(COMMUNITY_SET_ACTIVITIES_ERROR, response);

                return response;
            });
    },
    [COMMUNITY_SET_UNSEEN_COMMENTS](context, payload) {
        let url = ''
        if(context.getters.isClient) url = '/client/communities/' + context.getters.communityId + '/activity/' + payload.activityId + '/seeComents'
        else url = '/experienceLabs/tester/community/' + context.getters.communityId + '/activity/' + payload.activityId + '/seeComments'

        return ApiService
            .post(url, payload.commentIdsToSee)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response }) => {
                return response;
            });
    },
    [COMMUNITY_FETCH_ALLOWED_ACTIVITIES](context, payload) {
        return ApiService
            .get('/experienceLabs/client/communities/' + context.getters.communityId + '/activitiesAllowed')
            .then(({ data }) => data)
            .catch(({ response }) => response);
    },
    [ACTIVITY_STEP_1](context, payload) {
        return ApiService
            .post('/client/communities/' + context.getters.communityId + '/activity/step1', payload)
            .then(({ data }) => {
                return data;
            })
            .catch(() => {
                return Promise.reject({error: true})
            });
    },
    [ACTIVITY_STEP_2_TOPICS](context, payload) {
        return ApiService
            .post('/client/communities/' + context.getters.communityId + '/activity/' + payload.id + '/step2Debate', payload.data)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response }) => {
                return response;
            });
    },
    [ACTIVITY_STEP_2_HEATMAP](context, payload) {
        return ApiService
            .post('/client/communities/' + context.getters.communityId + '/activity/' + payload.id + '/step2HeatMap', payload.data)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response }) => {
                return response;
            });
    },
    [ACTIVITY_STEP_2_SURVEY](context, payload) {
        return ApiService
            .post('/client/communities/' + context.getters.communityId + '/activity/' + payload.id + '/step2Survey', payload.data)
    },
    [ACTIVITY_STEP_2_WATUX](context, payload) {
        return ApiService
            .post('/client/communities/' + context.getters.communityId + '/activity/' + payload.id + '/step2Ux', payload.data)
    },
    [ACTIVITY_STEP_3](context, payload) {
        return ApiService
            .post('/client/communities/' + context.getters.communityId + '/activity/' + payload.id + '/step3', payload.data)
    },
    [ACTIVITY_NAVIGATION_RULES](context, payload) {
        return ApiService
            .get('/test/' + payload.surveyId + '/navigationRules')
            .then(({ data }) => {
                return data;
            })
            .catch(({ response }) => {
                return response;
            });
    },
    [COMMUNITY_FETCH_ACTIVITY_USER_COMMENT_RECOMENDATION](context, payload) {
        return ApiService
            .get('/experienceLabs/community/' + context.getters.communityId + '/activity/' + context.getters.communityCurrentActivityId + '/users', payload)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response }) => {
                return response;
            });
    },
    [COMMUNITY_FETCH_ACTIVITY_UNSEEN_MENTION_PER_PAGE](context, payload) {
        return ApiService
            .get('/experienceLabs/' + (context.getters.isClient ? 'client' : 'tester') + '/community/' + context.getters.communityId + '/activity/' + payload.activityId + '/mentionsData')
            .then(({ data }) => {
                return data;
            })
            .catch(({ response }) => {
                return response;
            });
    },
    [ACTIVITY_RENEW_ANALYSIS_CHANGE_SUMMARY] (context, payload) {
        return ApiService
            .put('/experienceLabs/community/activity/' + payload.activityId + '/changeSummary')
            .then(({ data }) => {
                return data;
            })
            .catch(({ response }) => {
                return response;
            });
    },
    [ACTIVITY_FETCH_ANALYSIS_DATA_COMPLETE] (context, payload) {
        return ApiService
            .get('/experienceLabs/community/' + context.getters.communityId + '/activity/'+payload.activityId+'/analysisData')
            .then(({ data }) => {
                context.commit(COMMUNITY_ACTIVITY_SET_ANALYSIS_DATA, data)
                return Promise.resolve(data);
            })
            .catch(({ response }) => {
                return Promise.reject(response);
            });
    },
    [ACTIVITY_FETCH_ANALYSIS_DATA] (context, payload) {
        return ApiService
            .get('/experienceLabs/community/' + context.getters.communityId + '/activity/'+payload.activityId+'/analysis?topicsNum='+payload.topicsNum+'&modelParam='+payload.modelName)
            .then(({ data }) => {
                return Promise.resolve(data);
            })
            .catch(({ response }) => {
                return Promise.reject(response);
            });
    },
    [ACTIVITY_FETCH_ANALYSIS_BY_TOPIC] (context, payload) {
        return ApiService
            .get('/experienceLabs/community/' + context.getters.communityId + '/activity/'+payload.activityId+'/topic/'+payload.topicId+'/commentsPaged', payload)
            .then(({ data }) => {
                return Promise.resolve(data);
            })
            .catch(({ response }) => {
                return Promise.reject(response);
            });
    },
    [ACTIVITY_ADD_MODERATOR_TOPICS] (context, payload) {
        return ApiService
            .put('/experienceLabs/community/' + context.getters.communityId + '/activity/'+payload.activityId+'/moderatorTopics', payload.data)
            .then(({ data }) => {
                return Promise.resolve(data);
            })
            .catch(({ response }) => {
                return Promise.reject(response);
            });
    },
    [ACTIVITY_FETCH_MODERATOR_TOPICS] (context, payload) {
        return ApiService
            .get('/experienceLabs/community/' + context.getters.communityId + '/activity/'+payload.activityId+'/moderatorTopics')
            .then(({ data }) => {
                return Promise.resolve(data);
            })
            .catch(({ response }) => {
                return Promise.reject(response);
            });
    },
    [ACTIVITY_DELETE_MODERATOR_TOPICS] (context, payload) {
        return ApiService
            .delete('/experienceLabs/community/' + context.getters.communityId + '/activity/'+payload.activityId+'/topic/' + payload.topicId + '/moderatorTopics', payload.data)
            .then(({ data }) => {
                return Promise.resolve(data);
            })
            .catch(({ response }) => {
                return Promise.reject(response);
            });
    },
    [CALL_VIDEO_BACKEND_URL] (context, payload){
        const headers = {
            headers: {
                'Accept': 'video/mp4',
                'authToken': ApiService.getConfig().token()
            },
            responseType: 'blob'
        }
        return ApiService
        .get('/communitiesVideo/' + payload.text, null, headers)
        .then(({ data }) => {
            return Promise.resolve(data);
        })
        .catch(({ response }) => {
            return Promise.reject(response);
        });
    },
    [ACTIVITY_SET_RAFFLE_NOTIFICATED] (context, payload){
        return ApiService
        .post(`/experienceLabs/tester/community/${context.getters.communityId}/activity/${payload.activityId}/seeRaffleResult`)
    }
}

const mutations = {
    restoreActivities(state) {
        state.activities.state = state.activities.state.map(function (a) {
            a.showActions = false
            return a;
        })
    },

    setForceUpdateDefaultActivity(state, value) {
        state.forceUpdateDefaultActivity = value
    },

    showActionsActivity(state, activity) {
        activity.showActions = !activity.showActions;
    },

    onlineUserComment(state, data) {
        data.c.online = data.value
    },

    [COMMUNITY_SET_ACTIVITIES](state, activities) {
        state.activities.state = activities;
        state.activities.loading = false;
        state.activities.error = null;
    },

    [COMMUNITY_SET_ACTIVITIES_UNSEEN](state, activities) {
        state.activitiesClient.state = activities;
        state.activitiesClient.loading = false;
        state.activitiesClient.error = null;
    },

    [COMMUNITY_SET_ACTIVITIES_LOADING](state) {
        state.activities.loading = true;
    },

    [COMMUNITY_SET_ACTIVITIES_ERROR](state, error) {
        state.activities.error = error;
        state.activities.loading = false;
    },

    [COMMUNITY_SET_CURRENT_ACTIVITY](state, activityData) {
        state.currentActivity.id = activityData.identifier;
        state.currentActivity.activityData = activityData;
    },

    [COMMUNITY_SET_CURRENT_ACTIVITY_PARTICIPANTS](state, participants) {
        state.currentActivity.participants.state = participants;
        state.currentActivity.participants.loading = false;
        state.currentActivity.participants.error = null;
    },

    [COMMUNITY_SET_CURRENT_ACTIVITY_PARTICIPANTS_LOADING](state) {
        state.currentActivity.participants.loading = true;
    },

    [COMMUNITY_SET_CURRENT_ACTIVITY_PARTICIPANTS_ERROR](state, error) {
        state.currentActivity.participants.error = error;
        state.currentActivity.participants.loading = false;
    },

    [COMMUNITY_PURGE_CURRENT_ACTIVITY](state) {
        state.currentActivity = initialState.currentActivity;
    },

    [COMMUNITY_SET_ACTIVITY_COMMENTS](state, comments) {
        for (let c of comments.objects) {
            c.online = false
        }
        state.currentActivity.comments.state = comments;
        state.currentActivity.comments.loading = false;
        state.currentActivity.comments.error = null;
    },

    [COMMUNITY_SET_ACTIVITY_COMMENTS_LOADING](state) {
        state.currentActivity.comments.loading = true;
    },

    [COMMUNITY_SET_ACTIVITY_COMMENTS_ERROR](state, error) {
        state.currentActivity.comments.error = error;
        state.currentActivity.comments.loading = false;
    },
    [COMMUNITY_ACTIVITY_SET_ANALYSIS_DATA](state, data){
        state.activityAnalysis = data
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
