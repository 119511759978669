import store from '@/store';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'theme',
    ]),

    $themeProps() {
      return {
        '--primary-color': this.theme.mainColor,
        '--primary-color-text': this.theme.mainColorText,
        '--accent-color': this.theme.buttonColor,
        '--text-color': this.theme.textColor,
        '--gradient1-color':this.theme.backgroundGradientColor1,
        '--gradient2-color':this.theme.backgroundGradientColor2,
      };
    },
  },
}
