import ApiService from '~/services/api.service'
import store from '@/store';
import app from '@/main';
import Swal from 'sweetalert2'
import Bugsnag from '@bugsnag/js'

import {
  USER_AUTH_LOGOUT,
} from '@/store/user.module';
ApiService.setConfig( {
  baseURL: process.env.VUE_APP_API_URL,
  token: () => { return store.getters.authToken; },
  interceptors: [
    {
      type: 'request',
      success_cb: config => config,
      error_cb: error => Promise.reject( error )
    },
    {
      type: 'response',
      success_cb: response  => {
        return response;
      },
      error_cb:   error     => {
        const isNotLogin    = !(window.location.pathname.includes('/login') || window.location.pathname.includes('/backoffice'))
        const errorStatus   = error.response ? error.response.status : error.message;
        if ( errorStatus === 401 && isNotLogin ) {
          store.dispatch( USER_AUTH_LOGOUT );
          app.config.globalProperties.$router.push( { name: 'login', params: { origin: store.getters.origin } } );
        }
        const errorPath = error.config.url.replace(error.config.baseURL,"")
        const excludedErrorPaths = [
          `/experienceLabs/client/communities/${ store.getters.communityId }/alerts/page/0/pageSize/100`,
          `/experienceLabs/notifications/${ store.getters.communityId }/alerts/page/0/pageSize/100`,
          `/experienceLabs/${ store.getters.communityId }/onlineUsersCondensed`,
          `/experienceLabs/client/communities/${ store.getters.communityId }/onlineUsersCondensed`,
          `https://ipinfo.io/json`
        ]

        if ( errorStatus === 'Network Error' && !excludedErrorPaths.includes(errorPath)) {
          Bugsnag.notify('Network Error Communities - ' + store.getters.communityId, function (event) {
              event.severity = 'info'
              event.context = 'Network Error Logs - ' + errorPath
              event.setUser(store.getters.userIdentifier, '', '')
          })
          Swal.fire( {
            title     : '',
            text      : app.config.globalProperties.$filters.translate('error_network'),
            value     : true,
            icon      : 'error',
            visible   : true,
            className : 'alert500',
            closeModal: true,
            dangerMode: true,
            buttons   : [ false, app.config.globalProperties.$filters.translate('error_network_retry') ],
          } )
            .then( answer => {
              if ( answer ) app.config.globalProperties.$router.go( );
            } );
        }

        return Promise.reject( error );
      },
    }
  ]
} );

export default ApiService;
