import ApiService from '@/services/api.service';

export const FETCH_PUBLIC_FILTERS_DASHBOARD = 'FETCH_PUBLIC_FILTERS_DASHBOARD'
export const FETCH_TEST  = 'FETCH_TEST'
export const FETCH_MONITOR_REPORT_BASIC = 'FETCH_MONITOR_REPORT_BASIC'
export const FETCH_CLIENT_DASHBOARD_TABLE = 'FETCH_CLIENT_DASHBOARD_TABLE'
export const FETCH_CLIENT_DASHBOARD_TEXT = 'FETCH_CLIENT_DASHBOARD_TEXT'
export const SAVE_MONITOR_REPORT = 'SAVE_MONITOR_REPORT'

const state = {
  watMonitorTest:{},
}

const getters = {
  watMonitorTest(state: any){
    return state.watMonitorTest
  },
}

const actions = {
  [FETCH_TEST] (context: any, payload: any) {
    const { testId } = payload

    return ApiService
      .get('/clientDashboard/public/test/'+testId+'/data')
      .then(({data}: any) => {
        context.commit('setWatMonitorTest', data)
        return data
      })
      .catch(({response}: any) => {
        return response
      })
  },
  [FETCH_PUBLIC_FILTERS_DASHBOARD] (context: any, payload: any) {
    const testId = payload.testId

    return ApiService
      .get('/clientDashboard/public/test/' + testId + '/filterQuestions')
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any) => {
        return response
      })
  },
  [FETCH_MONITOR_REPORT_BASIC] (context: any, payload: any) {
    return ApiService
      .get('/monitor'+(isNaN(payload.testId)?'/public/':'/')+payload.testId+'/basicReport', payload.data)
  },
  [FETCH_CLIENT_DASHBOARD_TABLE] (context: any, payload: any) {
    return ApiService
      .post('/clientDashboard'+(isNaN(payload.testId)? "/public/" : "/")+'test/' + payload.testId + '/dashboardv2', payload.data)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any) => {
        return response
      })
  },
  [FETCH_CLIENT_DASHBOARD_TEXT] (context: any, payload: any) {
    return ApiService
      .post('/clientDashboard'+(isNaN(payload.testId)? "/public/" : "/")+'test/' + payload.testId + '/dashboardTextv2', payload.data)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any) => {
        return response
      })
  },
  [SAVE_MONITOR_REPORT] (context: any, payload: any) {
    return ApiService
      .put('/monitor'+(isNaN(payload.testId)?'/public/':'/')+payload.testId+'/report/'+payload.reportId, payload.data)
  },
};

const mutations = {
  setWatMonitorTest(state: any,data: any){
    state.watMonitorTest = data;
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};
