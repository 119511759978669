import ApiService from '@/services/api.service';
import { VueCookieNext } from 'vue-cookie-next'
import Swal from 'sweetalert2'

  const state = {
    creditsPlan:{},
    notifications:{
      alerts:[],
      popUp:[],
      unseenAlerts:0
    }
  }

  const getters = {
    clientCreditsPlan(state: any){
      return state.creditsPlan
    },
    notificationsAlert(state: any){
      return state.notifications.alerts
    },
    notificationsUnseen(state: any){
      return state.notifications.unseenAlerts
    }
  }

  const actions = {
    async getCreditsPlan(context: any, payload: any){
      return ApiService
        .get('/clientSuite/creditsPlan/planData', null)
        .then(({data}: any) => {
          context.commit('setStateCreditsPlan',data)
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getNotifications(context: any, payload: any){
      return ApiService
        .get('/clientSuite/clientNotifications', payload)
        .then(({data}: any) => {
          context.commit('setStateNotifications',data)
          if(data.popup) context.dispatch('notifyPopUp', data.popup)
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async seeNotification(context: any, payload: any){
      return ApiService
        .post('/clientSuite/clientNotifications/seen', payload)
        .then(({data}: any) => {
          context.dispatch('getNotifications')
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    }
  }

  const mutations = {
    setStateCreditsPlan(state: any,data: any){
      state.creditsPlan = data;
    },
    setStateNotifications(state: any,data: any){
      state.notifications = data;
    },
  }

export default{
  state,
  getters,
  actions,
  mutations
}
