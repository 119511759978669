import ApiService from '@/services/api.service';

export const COMMUNITY_FETCH_CHAT_MESSAGES = 'COMMUNITY_FETCH_CHAT_MESSAGES';
export const COMMUNITY_ADD_CHAT_MESSAGE = 'COMMUNITY_ADD_CHAT_MESSAGE';
export const COMMUNITY_SEE_CHAT_MESSAGES = 'COMMUNITY_SEE_CHAT_MESSAGES'
export const COMMUNITY_SEE_CHAT_MESSAGES_OBS = 'COMMUNITY_SEE_CHAT_MESSAGES_OBS'
export const COMMUNITY_FETCH_CHAT_LIST = 'COMMUNITY_FETCH_CHAT_LIST'
export const COMMUNITY_SET_CHAT_UNSEEN = 'COMMUNITY_SET_CHAT_UNSEEN'
export const COMMUNITY_SET_CHAT_USER_UNSEEN = 'COMMUNITY_SET_CHAT_USER_UNSEEN'
export const COMMUNITY_SET_CHAT_USER_UNSEEN_UPDATE = 'COMMUNITY_SET_CHAT_USER_UNSEEN_UPDATE'
export const COMMUNITY_SET_CHAT_UNSEEN_ADD = 'COMMUNITY_SET_CHAT_UNSEEN_ADD'
export const COMMUNITY_SET_CHAT_UNSEEN_DELETE = 'COMMUNITY_SET_CHAT_UNSEEN_DELETE'
export const COMMUNITY_SET_CHAT_LIST = 'COMMUNITY_SET_CHAT_LIST'
export const COMMUNITY_SET_CHAT_MESSAGES = 'COMMUNITY_SET_CHAT_MESSAGES'
export const COMMUNITY_SET_CHAT_LOADING = 'COMMUNITY_SET_CHAT_LOADING';
export const COMMUNITY_SET_CHAT_ERROR = 'COMMUNITY_SET_CHAT_ERROR';
export const COMMUNITY_DELETE_CHAT_MESSAGE = 'COMMUNITY_DELETE_CHAT_MESSAGE';
export const COMMUNITY_FETCH_VIDEO_CHAT = 'COMMUNITY_FETCH_VIDEO_CHAT';
export const COMMUNITY_START_VIDEO_RECORD = 'COMMUNITY_START_VIDEO_RECORD'
export const COMMUNITY_STOP_VIDEO_RECORD = 'COMMUNITY_STOP_VIDEO_RECORD'
export const COMMUNITY_DELETE_VIDEO_RECORD = 'COMMUNITY_DELETE_VIDEO_RECORD'
export const COMMUNITY_FETCH_VIDEO_RECORD = 'COMMUNITY_FETCH_VIDEO_RECORD'
export const COMMUNITY_SET_VIDEO_AUDIO_TEST = 'COMMUNITY_SET_VIDEO_AUDIO_TEST';
export const COMMUNITY_SET_VIDEO_AUDIO_TEST_PREVIEW = 'COMMUNITY_SET_VIDEO_AUDIO_TEST_PREVIEW';
export const COMMUNITY_VALIDATE_VIDEO_CHAT_PASSWORD = 'COMMUNITY_VALIDATE_VIDEO_CHAT_PASSWORD';
export const COMMUNITY_FETCH_VIDEO_AUDIO_TEST = 'COMMUNITY_FETCH_VIDEO_AUDIO_TEST';
export const COMMUNITY_PUT_VIDEO_CHAT_MESSAGE = 'COMMUNITY_PUT_VIDEO_CHAT_MESSAGE';
export const COMMUNITY_GET_VIDEO_CHAT_MESSAGE = 'COMMUNITY_GET_VIDEO_CHAT_MESSAGE';
export const COMMUNITY_PUT_VIDEO_CHAT_MESSAGE_OBS = 'COMMUNITY_PUT_VIDEO_CHAT_MESSAGE_OBS';
export const COMMUNITY_GET_VIDEO_CHAT_MESSAGE_OBS = 'COMMUNITY_GET_VIDEO_CHAT_MESSAGE_OBS';
export const COMMUNITY_SET_CHAT_MESSAGES_OBS = 'COMMUNITY_SET_CHAT_MESSAGES_OBS';
export const COMMUNITY_GRANT_ACCESS_TO_VIDEOCHAT = 'COMMUNITY_GRANT_ACCESS_TO_VIDEOCHAT';
export const COMMUNITY_FETCH_CHAT_UNSEEN  = 'COMMUNITY_FETCH_CHAT_UNSEEN';
export const COMMUNITY_VIDEOCHAT_ADD_USER_PUBLIC = 'COMMUNITY_VIDEOCHAT_ADD_USER_PUBLIC'
export const COMMUNITY_VIDEOCHAT_ADD_OBSERVER_PUBLIC = 'COMMUNITY_VIDEOCHAT_ADD_OBSERVER_PUBLIC'
export const COMMUNITY_VIDEOCHAT_FILE = 'COMMUNITY_VIDEOCHAT_FILE'
export const COMMUNITY_VIDEOCHAT_FILE_COMMENT = 'COMMUNITY_VIDEOCHAT_FILE_COMMENT';
export const COMMUNITY_GET_VIDEO_CHAT_ACTIVITY_MESSAGE_OBS = 'COMMUNITY_GET_VIDEO_CHAT_ACTIVITY_MESSAGE_OBS'
export const COMMUNITY_PUT_VIDEO_CHAT_ACTIVITY_MESSAGE_OBS = 'COMMUNITY_PUT_VIDEO_CHAT_ACTIVITY_MESSAGE_OBS'

export const COMMUNITY_VIDEOCHAT_FETCH_TRANSCRIPTION = 'COMMUNITY_VIDEOCHAT_FETCH_TRANSCRIPTION'
export const COMMUNITY_UX_FETCH_TRANSCRIPTION = 'COMMUNITY_UX_FETCH_TRANSCRIPTION'
export const COMMUNITY_VIDEOCHAT_SEARCH_TRANSCRIPTION = 'COMMUNITY_VIDEOCHAT_SEARCH_TRANSCRIPTION'
export const COMMUNITY_VIDEOCHAT_FETCH_TRANSCRIPTION_WORD = 'COMMUNITY_VIDEOCHAT_FETCH_TRANSCRIPTION_WORD'
export const COMMUNITY_VIDEOCHAT_SET_TRANSCRIPTION_NAME = 'COMMUNITY_VIDEOCHAT_SET_TRANSCRIPTION_NAME'

const initialState = {
  chat: {
    state: [],
    notSeenCount:null,
    selectedChat:null,
    list:[],
    messages:[],
    messagesObs:[],
    loading: false,
    error: null,
    enlargedPic: null
  },
};

const state = initialState;

const getters = {
  communityChatList(state){
    return state.chat.list
  },

  communityChatMessages(state){
    return state.chat.messages
  },

  communityChatMessagesObs(state){
    return state.chat.messagesObs
  },

  communityTotalNotSeen(state){
    return state.chat.notSeenCount
  },

  communitySelectedChat(state){
    return state.chat.selectedChat
  },

  enlargedPic ( state ) {
    return state.chat.enlargedPic;
  }
};

const actions = {
  [COMMUNITY_FETCH_CHAT_UNSEEN] (context, payload) {
    return ApiService
      .get('/client/communities/' + context.getters.communityId + '/chatUnseen')
      .then(({data}) => {
        context.commit(COMMUNITY_SET_CHAT_UNSEEN, data);
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_FETCH_CHAT_LIST] (context, payload) {
    const { order, filter, page, pageSize, updateChatList } = payload;

    let query = {
      order,
      filter,
      page,
      pageSize
    };
    return ApiService
      .get('/client/communities/' + context.getters.communityId + '/chatList',query)
      .then(({data}) => {
        const dataChatList = {
          chatList:data,
          updateChatList:updateChatList,
        }
        context.commit(COMMUNITY_SET_CHAT_LIST, dataChatList);
        return data
      })
      .catch(({response}) => response);
  },

  [COMMUNITY_FETCH_CHAT_MESSAGES] (context, payload) {
    const { userId } = payload

    return ApiService
      .get((context.getters.isClient ? 'client' : 'tester') + '/communities/' + context.getters.communityId + '/chat'+(context.getters.isClient ? '/'+userId : ''))
      .then(({data}) => {
        context.commit(COMMUNITY_SET_CHAT_MESSAGES, data);
        if (context.getters.isUser) {
          context.commit(COMMUNITY_SET_CHAT_USER_UNSEEN,data);
        }
        return data
      })
      .catch(({response}) => response);
  },

  [COMMUNITY_SEE_CHAT_MESSAGES] (context, payload) {
    const { userId } = payload
    return ApiService
      .post((context.getters.isClient ? 'client' : 'tester') + '/communities/' + context.getters.communityId + '/chat'+(context.getters.isClient ? '/'+userId : '')+'/see', {messageIds:payload.messageIds})
      .then(({data}) => data)
      .catch(({response}) => response);
  },

  [COMMUNITY_SEE_CHAT_MESSAGES_OBS] (context, payload) {
    const { userId } = payload
    return ApiService
      .post('client/communities/' + context.getters.communityId + '/chat/'+userId+'/seeObs', {messageIds:payload.messageIds})
      .then(({data}) => data)
      .catch(({response}) => response);
  },

  [COMMUNITY_ADD_CHAT_MESSAGE] (context, payload) {
    const { message, userId, image, video, file } = payload

    const data = image ? { message, image } : { message };
    data.image = image
    data.video = video
    data.file = file
    return ApiService
      .post((context.getters.isClient ? 'client' : 'tester') + '/communities/' + context.getters.communityId + '/chat'+(context.getters.isClient ? '/'+userId : ''), data )
      .then(({data}) => data)
      .catch(({response}) => response);
    },
    [COMMUNITY_DELETE_CHAT_MESSAGE](context, payload) { 
        return ApiService
            .delete(
          '/client/communities/' +context.getters.communityId +'/chat/deleteMessages',{ messageIds: payload.messageIds })
    },

  [COMMUNITY_SET_CHAT_USER_UNSEEN_UPDATE] (context, payload) {
    if (payload.delete) {
      context.commit(COMMUNITY_SET_CHAT_UNSEEN_DELETE, payload);
    }
    else{
      context.commit(COMMUNITY_SET_CHAT_UNSEEN_ADD, payload);
    }
  },
  [COMMUNITY_FETCH_VIDEO_CHAT] (context, payload) {
    return ApiService
      .get('/experienceLabs/'+(context.getters.isClient?'client/':'') + context.getters.communityId + '/activity/'+context.getters.communityCurrentActivityId + (payload.isObservator ? "/videochatObserver" : "/videochatData"))
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_START_VIDEO_RECORD] (context, payload) {
    return ApiService
      .post('/experienceLabs/client/'+context.getters.communityId+'/activity/'+context.getters.communityCurrentActivityId +'/videoRecord/start')
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_STOP_VIDEO_RECORD] (context, payload) {
    return ApiService
      .post('/experienceLabs/client/'+context.getters.communityId+'/activity/'+context.getters.communityCurrentActivityId +'/videoRecord/stop')
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_DELETE_VIDEO_RECORD] (context, payload) {
    return ApiService
      .delete('/experienceLabs/client/'+context.getters.communityId+'/activity/'+payload.activityId +'/videoRecord', payload.data)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_FETCH_VIDEO_RECORD] (context, payload) {
    return ApiService
      .get('/experienceLabs/client/'+context.getters.communityId+'/activity/'+payload.id +'/videoRecords')
      .then(({data}) => {
        return data
      })
      .catch(({ response }) => Promise.reject(response));
    },
  [COMMUNITY_SET_VIDEO_AUDIO_TEST] (context, payload) {
    return ApiService
      .post('/experienceLabs/'+context.getters.communityId+'/activity/'+context.getters.communityCurrentActivityId +'/videochatTestResults',payload)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_SET_VIDEO_AUDIO_TEST_PREVIEW] (context, payload) {
    return ApiService
      .post('/experienceLabs/'+context.getters.communityId+'/videochatPublicTestResults',payload)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_VALIDATE_VIDEO_CHAT_PASSWORD] (context, payload) {
    return ApiService
      .post('/experienceLabs/'+context.getters.communityId+'/activity/'+payload.id+'/passwordValidation',{password:payload.password})
      .then(({data}) => {
        return data
      })
      .catch(({response}) => Promise.reject(response));
  },

  [COMMUNITY_FETCH_VIDEO_AUDIO_TEST] (context, payload) {
    return ApiService
      .get('/experienceLabs/client/'+context.getters.communityId+'/activity/'+context.getters.communityCurrentActivityId +'/videochatTestResults')
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_PUT_VIDEO_CHAT_MESSAGE] (context, payload) {
    return ApiService
      .post((context.getters.isClient ? '/client': '/tester')+'/communities/'+context.getters.communityId+'/activity/'+context.getters.communityCurrentActivityId +'/chat',payload)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_GET_VIDEO_CHAT_MESSAGE] (context, payload) {
    let activityId = payload.id != undefined ? payload.id : context.getters.communityCurrentActivityId
    return ApiService
      .get((context.getters.isClient ? '/client': '/tester')+'/communities/'+context.getters.communityId+'/activity/'+activityId +'/chat')
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_PUT_VIDEO_CHAT_MESSAGE_OBS] (context, payload) {
    return ApiService
      .post('/client/communities/'+context.getters.communityId+'/chat'+(context.getters.isModerator ? '/toObserver': '/fromObserver'),payload)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_PUT_VIDEO_CHAT_ACTIVITY_MESSAGE_OBS] (context, payload) {
    return ApiService
      .post('/client/communities/'+context.getters.communityId+'/activity/'+context.getters.communityCurrentActivityId+'/chat'+(context.getters.isModerator ? '/toObserver': '/fromObserver'),payload)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_GET_VIDEO_CHAT_MESSAGE_OBS] (context, payload) {
    return ApiService
      .get('/client/communities/'+context.getters.communityId+'/chat'+(context.getters.isModerator ? '/withObserver': '/observer'))
      .then(({data}) => {
        context.commit(COMMUNITY_SET_CHAT_MESSAGES_OBS, data);
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_GET_VIDEO_CHAT_ACTIVITY_MESSAGE_OBS] (context, payload) {
    return ApiService
      .get('/client/communities/'+context.getters.communityId+'/activity/'+context.getters.communityCurrentActivityId+'/chat'+(context.getters.isModerator ? '/withObserver': '/observer'))
      .then(({data}) => {
        context.commit(COMMUNITY_SET_CHAT_MESSAGES_OBS, data);
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_GRANT_ACCESS_TO_VIDEOCHAT] (context, payload) {
    return ApiService
      .post('/experienceLabs/client/'+context.getters.communityId+'/activity/'+payload.activityId+'/tester/'+payload.testerId+'/grantAccess',payload.grantAccess)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_VIDEOCHAT_FILE] (context, payload) {
    return ApiService
      .get('/experienceLabs/client/archive/'+payload.archiveId)
  },
  [COMMUNITY_VIDEOCHAT_FILE_COMMENT] (context, payload) {
    return ApiService
      .post('/experienceLabs/client/' + context.getters.communityId + '/activity/' + payload.activityId + '/videoComment', payload.data)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_VIDEOCHAT_ADD_USER_PUBLIC] (context, payload) {
    return ApiService
      .post('/experienceLabs/teser/' + context.getters.communityId + '/activity/' + payload.activityId + '/publicVideochatJoin')
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_VIDEOCHAT_ADD_OBSERVER_PUBLIC] (context, payload) {
    return ApiService
      .post('/experienceLabs/teser/' + context.getters.communityId + '/activity/' + payload.activityId + '/observerVideochatJoin', payload.data)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => Promise.reject(response));
  },
  [COMMUNITY_VIDEOCHAT_FETCH_TRANSCRIPTION] (context, payload) {
    return ApiService
      .get(`/experienceLabs/client/communities/${context.getters.communityId}/activity/${payload.activityId}/transcription`)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_UX_FETCH_TRANSCRIPTION] (context, payload) {
    return ApiService
      .get(`/experienceLabs/client/communities/${context.getters.communityId}/activity/${payload.activityId}/uxTranscription`)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_VIDEOCHAT_SEARCH_TRANSCRIPTION] (context, payload) {
    return ApiService
      .post(`/experienceLabs/client/communities/${context.getters.communityId}/activity/${payload.activityId}/transcriptionSearch`, payload.data)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_VIDEOCHAT_FETCH_TRANSCRIPTION_WORD] (context, payload) {
    const headers = {
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'authToken': ApiService.getConfig().token()
      },
      responseType: 'blob'
    }
    return ApiService
      .get(`/experienceLabs/client/communities/${context.getters.communityId}/activity/${payload.activityId}/video/${payload.videoId}/transcriptionWord`,null, headers)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => Promise.reject(response));
  },
  [COMMUNITY_VIDEOCHAT_SET_TRANSCRIPTION_NAME] (context, payload) {
    return ApiService
      .put(`/experienceLabs/client/communities/${context.getters.communityId}/activity/${payload.activityId}/video/${payload.videoId}/speakerNames`, payload.data)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  }
};

const mutations = {
  changeChat (state, chat) {
    state.chat.selectedChat = chat
    if (state.chat.list != undefined) {
      state.chat.list = state.chat.list.map(function(a) {
        a.selected = false
        if (a.identifier == chat) {
          a.selected = true
          state.chat.notSeenCount = state.chat.notSeenCount - a.notSeenCount
          a.notSeenCount = 0
        }
        return a;
      })
    }
  },

  enlargePic (state, pic) {
    state.chat.enlargedPic = pic
  },

  addChatNotification (state, chat){
    state.chat.list = state.chat.list.map(function(a) {
      if (a.identifier == chat) {
        a.notSeenCount = a.notSeenCount + 1
      }
      return a;
    })
  },

  addChatMessage(state, message){
    state.chat.messages.push(message)
  },
  addChatMessageObs(state, message){
    state.chat.messagesObs.push(message)
  },
  removeNotSeenCount(state){
    state.chat.notSeenCount = 0
  },

  chatOnline(state, data){
    data.chat.online = data.value
  },

  [COMMUNITY_SET_CHAT_LIST] (state,data){
    for(let c of data.chatList.chats){
      c.online = false
    }
    if(data.updateChatList) state.chat.list = state.chat.list.concat(data.chatList.chats)
    else state.chat.list = data.chatList.chats;
    state.chat.loading = false;
    state.chat.error = null;
  },

  [COMMUNITY_SET_CHAT_MESSAGES] (state,messages){
    state.chat.messages = messages.messages;
    state.chat.loading = false;
    state.chat.error = null;
  },

  [COMMUNITY_SET_CHAT_MESSAGES_OBS] (state, messages){
    state.chat.messagesObs = messages.messages;
  },

  [COMMUNITY_SET_CHAT_UNSEEN] (state, info) {
    state.chat.notSeenCount = info.unseen;
    state.chat.loading = true;
    state.chat.error = null;
  },

  [COMMUNITY_SET_CHAT_UNSEEN_ADD] (state, info) {
    state.chat.notSeenCount = state.chat.notSeenCount + info.totalNotSeen;
    state.chat.loading = true;
    state.chat.error = null;
  },

  [COMMUNITY_SET_CHAT_UNSEEN_DELETE] (state, info) {
    state.chat.notSeenCount = state.chat.notSeenCount - info.totalNotSeen;
    state.chat.loading = true;
    state.chat.error = null;
  },

  [COMMUNITY_SET_CHAT_USER_UNSEEN] (state, info) {
    state.chat.notSeenCount = info.notSeenCount;
    state.chat.loading = true;
    state.chat.error = null;
  },

  [COMMUNITY_SET_CHAT_LOADING] (state) {
    state.chat.loading = true;
  },

  [COMMUNITY_SET_CHAT_ERROR] (state, error) {
    state.chat.error = error;
    state.chat.loading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
