import ApiService from '@/services/api.service';

export const MISC_FETCH_PROVINCES = 'MISC_FETCH_PROVINCES';
export const MISC_FETCH_COUNTRY_CODE = 'MISC_FETCH_COUNTRY_CODE';
export const MISC_SET_PROVINCES = 'MISC_SET_PROVINCES';
export const MISC_SET_COUNTRY_CODE = 'MISC_SET_COUNTRY_CODE';
export const MISC_SET_PROVINCES_LOADING = 'MISC_SET_PROVINCES_LOADING';
export const MISC_SET_PROVINCES_ERROR = 'MISC_SET_PROVINCES_ERROR';

export const MISC_FETCH_REGIONS = 'MISC_FETCH_REGIONS';
export const MISC_FETCH_NIELSEN = 'MISC_FETCH_NIELSEN';

const initialState = {
  provinces: {
    state: [],
    loading: false,
    error: null,
  },
  country: {
    state:[],
  }
};

const state = initialState;

const getters = {
  provinces(state) {
    return state.provinces.state;
  },
  country(state){
    return state.country.state
  }
};

const actions = {
  [MISC_FETCH_PROVINCES] ( context, payload ) {
    context.commit( MISC_SET_PROVINCES_LOADING);
    let country = context.getters.country
    return ApiService
      .get( '/lovs/provinces/elements/filter/'+ country )
      .then( ( { data } ) => {
        context.commit( MISC_SET_PROVINCES, data );
        return data;
      })
      .catch( ( { response } ) => {
        context.commit( MISC_SET_PROVINCES_ERROR, response );
        return response;
      } );
  },
  [MISC_FETCH_REGIONS] ( context, payload ) {
    let country = context.getters.country
    return ApiService
      .get( '/lovs/regions/elements/filter/'+ country )
      .then( ( { data } ) => {
        return data;
      })
      .catch( ( { response } ) => {
        return response;
      } );
  },
  [MISC_FETCH_NIELSEN] ( context, payload ) {
    let country = context.getters.country
    return ApiService
      .get( '/lovs/nielsen/elements/filter/'+ country )
      .then( ( { data } ) => {
        return data;
      })
      .catch( ( { response } ) => {
        return response;
      } );
  },
  [MISC_FETCH_COUNTRY_CODE](context, payload){
    return ApiService
      .get( '/lovs/countries/elements')
      .then( ( { data } ) => {
        for(let c of data){
          if(!context.getters.theme.country){
            context.commit( MISC_SET_COUNTRY_CODE, "ES" );
            return "ES"
          }
          else if(c.id == context.getters.theme.country.elementId){
            context.commit( MISC_SET_COUNTRY_CODE, c.secondaryValue );
            return c.secondaryValue
          }
        }
      })
  },
};

const mutations = {
  [MISC_SET_PROVINCES] ( state, payload ) {
    state.provinces.state = payload;
    state.provinces.loading = false;
    state.provinces.error = null;
  },

  [MISC_SET_COUNTRY_CODE] ( state, payload ){
    state.country.state = payload;
  },

  [MISC_SET_PROVINCES_LOADING] (state) {
    state.provinces.loading = true;
  },

  [MISC_SET_PROVINCES_ERROR] ( state, error ) {
    state.provinces.error = error;
    state.provinces.loading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
