import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist';
import appModule from './app.module.ts'
import userModule from './user.module.ts'
import monitorModule from './monitor.module.ts'
import surveyExecutionModule from './surveyExecution.module.js'
import CommunityModule from './community';
import MiscModule from './misc';
import NotificationsModule from './notifications';
import watuxModule from '~/store/watux.store'
import suiteModule from './suite.module.ts'
export interface State {
  appModule: any
  userModule: any
  monitorModule: any
  surveyExecutionModule: any
  CommunityModule: any
  MiscModule: any
  NotificationsModule: any
  watuxModule: any
  suiteModule: any
}

const vuexPersist = new VuexPersistence({
  reducer: (state: any) => ({
    user: {
      auth: {
        state: state.user.auth.state,
      },
    },
    app: {
      theme: {
        state: state.app.theme.state,
      },
    },
  }),
});


export default createStore<State>({
  modules: {
    app: appModule,
    user: userModule,
    monitorModule: monitorModule,
    surveyExecutionModule: surveyExecutionModule,
    CommunityModule: CommunityModule,
    MiscModule: MiscModule,
    NotificationsModule: NotificationsModule,
    watuxModule: watuxModule,
    suiteModule: suiteModule
  },
  plugins: [
    vuexPersist.plugin,
  ]
});
