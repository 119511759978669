import axios from 'axios';
import queryString from 'query-string';
import { pickBy, assign, identity } from "lodash";

let config:any = {
  'withCredentials': true,
  'Access-Control-Allow-Credentials': true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'authToken': null
  },
  responseType:"",
  data:{}
}

interface Interceptor {
  type: string;
  success_cb: any;
  error_cb: any;
}

const updateConfig = (headers: any) => {
  config =  pickBy( assign({}, config, headers), identity);
  if ( config.interceptors ) {
    config.interceptors.map( (int: Interceptor) => {
      if(int.type == 'request') axios.interceptors.request.use( int.success_cb, int.error_cb )
      if(int.type == 'response') axios.interceptors.response.use( int.success_cb, int.error_cb )
    } );
  }
}

const setAuthToken = () => {
  updateConfig({
    headers: {
      'authToken': config.token(),
    }
  })
};

const actions = {
  setConfig(customConfig: any) {
    updateConfig(customConfig);
  },

  getConfig(){
    return config
  },

  get(path:any, params:any, headers?: any) {
    setAuthToken();
    if(headers) updateConfig(headers)
    let query = '';

    if(params) {
      query = '?' + queryString.stringify(params);
    }
    if(headers == undefined){
      config.responseType = ""
    }

    return axios.get(path + query, config);
  },

  post(path:any, body:any, headers?: any) {
    setAuthToken();
    if(headers) updateConfig(headers)
    if(headers == undefined){
      config.responseType = ""
    }
    return axios.post(path, body, config);
  },


  postFile(path:any, body:any, headers: any) {
    setAuthToken();
    updateConfig(headers)
    config.responseType = ""
    config.data = undefined
    return axios.post(path, body, config);
  },

  put(path:any, body:any, headers?: any) {
    setAuthToken();
    if(headers) updateConfig(headers)
    if(headers == undefined){
      config.responseType = ""
    }
    return axios.put(path, body, config);
  },

  delete(path:any, body:any, headers?: any) {
    setAuthToken();
    if(headers) updateConfig(headers)
    if(headers == undefined){
      config.responseType = ""
    }
    config.data = body
    return axios.delete(config.baseURL + path, { headers: config.headers, data: body});
  },
}
const ApiService = actions
export default ApiService;
