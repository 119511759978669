import i18n from '@/i18n'
import moment from 'moment-timezone';
import { map,flatMap } from 'lodash';

export const filters = {
  translate(value: String) {
    return i18n.global.t(value)
  },
  formatDateFull(date: any){
    if (date == undefined) {
      return "-"
    }
    return moment(date).format("DD/MM/YY HH:mm");
  },
  formatActivityType(type: any){
    let typeFormated = '-'

    switch (type) {
      case 'WAT_CHAT':
        typeFormated = 'activity_type_wat_chat'
        break;
      case 'EL_SURVEY':
      case 'WAT_SURVEY':
        typeFormated = 'activity_type_el_survey'
        break;
      case 'WAT_OPINION':
        typeFormated = 'activity_type_wat_opinion'
        break;
      case 'WAT_TASK':
        typeFormated = 'activity_type_wat_task'
        break;
      case 'WAT_POLL':
        typeFormated = 'activity_type_wat_poll'
        break;
      case 'VIDEOCHAT':
        typeFormated = 'activity_type_wat_videochat'
        break;
      case 'EL_HEATMAP':
        typeFormated = 'activity_type_el_heatmap'
        break;
      case 'WAT_UX':
        typeFormated = 'activity_type_wat_ux'
        break;
    }

    return typeFormated
  },
  formatActivityStatus(status: any){
    let statusFormated = '-'

    switch(status){
      case 'FINISHED':
        statusFormated = 'activity_stat_finished'
      break;
      case 'NEW':
        statusFormated = 'activity_stat_coming_soon'
      break;
      case 'ACTIVE':
        statusFormated = 'activity_stat_in_progress'
      break;
      case 'CONFIGURING':
        statusFormated = 'activity_stat_creating'
    }
    return statusFormated
  },
  formatDate(date: any, format: any){
    if (date == undefined) {
      return "-"
    }
    if(format != undefined && format != null) return moment(date).format(format);
    return moment(date).format("DD/MM HH:mm");
  },
  formatMediaWelcomePage(media: any) {
    return map(media, (m) => {
      return { src: m.path }
    })
  },
  formatMedia(media: any){
    return map(media, (path) => {
      return { src: path }
    })
  },
  formatVideo(video: any){
    return map(video, (v: any) => {
      return { src: v.videoUrl, title:v.videoTitle }
    })
  },
  formatVideoMedia: function (video: any){
    return map(video, (v) => {
      return { src: v }
    })
  },
  formatWelcomeVideo: function (videos: any){
    return map(videos, (v) => {
      return { src: v.path }
    })
  },
  formatYouTubeVideo(media: any){
    if (media == undefined) {
      return []
    }
    return [{ src : media }]
  },
  formatRedeemType(type: String){
    switch (type) {
      case "AMAZON":
        return "Amazon";
        break;
      case "PAYPAL":
        return "PayPal";
      case "RAFFLE":
        return "Sorteo";
    }
  },
  formatChartMedia(media:any){
    let data = []
    for(let m of media){
      if(m.length > 0)data.push({src:m})
    }
    return data
  },
  // formatDay(date: any){
  //   let formatDate = new Date(date);
  //   return formatDate.getDate() < 10 ? "0" + formatDate.getDate() : formatDate.getDate()
  // },
  //
  // formatMonth(date: any){
  //   let formatDate = new Date(date);
  //   return formatDate.getMonth() + 1 < 10 ? "0" + (formatDate.getMonth()+1): formatDate.getMonth() + 1
  // },
  //
  // formatYear(date: any){
  //   let formatDate = new Date(date);
  //   return formatDate.getFullYear().toString().substr(-2)
  // }
}
