import ApiService from '@/services/api.service';
import {USER_PURGE} from './user.module';
import i18n from '../i18n';
import moment from 'moment-timezone'

import {
  APP_FETCH_THEME,
  APP_UPDATE_THEME
} from './actions.type';

import {
  APP_SET_THEME,
  APP_SET_THEME_LOADING,
  APP_SET_THEME_ERROR,
  APP_SET_ORIGIN,
} from './mutations.type';

export const UPDATE_PERSONALIZATION_FULL = 'UPDATE_PERSONALIZATION_FULL'

const initialState = {
  origin: null,
  theme: {
    state: {},
    loading: false,
    error: null,
  },
  errors: {},
  data: {},
};

const state = initialState;

const getters = {
  origin(state: any) {
    return state.origin;
  },

  theme(state: any) {
    return state.theme.state;
  },

  communityId(state: any) {
    return state.theme.state.communityId;
  },
};

const actions = {
  [APP_FETCH_THEME] (context: any, payload: any) {
    const { origin } = payload;

    // context.commit(APP_SET_ORIGIN, origin);
    context.commit(APP_SET_THEME_LOADING);

    return ApiService
      .get('/experienceLabs/personalization/' + origin)
      .then(({data}: any) => {
        context.commit(APP_SET_THEME, data);
        i18n.global.locale = data.language ? (data.language == "nl-BE"? "nl" : data.language) : "es"
        moment.locale(data.language ? (data.language == "nl-BE"? "nl" : data.language) : "es")
        return data;
      })
      .catch(({response}: any) => {
        context.commit(APP_SET_THEME_ERROR, response);
        if (response.data.payload == "Community does not exist") {
          context.commit(USER_PURGE);
        }
        return response;
      })
  },

  [APP_UPDATE_THEME] ( context: any, payload: any ) {
    const { origin } = payload;

    context.commit( APP_SET_THEME_LOADING );

    return ApiService
      .put( `/experienceLabs/community/${ origin }/personalization`, payload.data )
        .then( ( { data }: any ) => {
          return data;
        } )
        .catch( ( { response }: any ) => {
          context.commit( APP_SET_THEME_ERROR, response );
          return response;
        } )
  },
  [UPDATE_PERSONALIZATION_FULL] ( context: any, payload: any ) {
    const { origin } = payload;

    return ApiService
      .put( `/experienceLabs/community/${ origin }/personalizationFull`, payload.data )
        .then( ( { data }: any ) => {
          return data;
        } )
        .catch( ( { response }: any ) => {
          return Promise.reject(response);
        } )
  },
  async uploadFile(context: any, payload: any){
    var formData = new FormData();
    formData.append("file", payload.file);
    formData.append("publicLogo", payload.publicLogo);
    const headers = {
      'Content-Type': 'multipart/form-data'
    }

    return ApiService
      .postFile('/experienceLabs/file', formData, headers)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return response;
      })
  }
};

const mutations = {

  updateThemePropsTemp (state: any, data: any){
    state.theme.state[data.prop] = data.value
    if (data.prop == 'favicon') {
      let favicon = <HTMLLinkElement>document.getElementById('favicon')
      if(favicon) favicon.href = data.value;
    }
  },

  [APP_SET_THEME] (state: any, theme: any) {
    state.theme.state = theme;
    state.theme.error = null;
    state.theme.loading = false;
  },

  [APP_SET_THEME_LOADING] (state: any) {
    state.theme.loading = true;
  },

  [APP_SET_THEME_ERROR] (state: any, error: any) {
    state.theme.error = error;
    state.theme.loading = false;
  },

  [APP_SET_ORIGIN] (state: any, origin: any) {
    state.origin = origin;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
