import ApiService from '@/services/api.service';
import { find } from 'lodash';

export const COMMUNITY_FETCH_MODERATORS = 'COMMUNITY_FETCH_MODERATORS';
export const COMMUNITY_FETCH_MODERATOR = 'COMMUNITY_FETCH_MODERATOR';
export const COMMUNITY_SET_MODERATORS = 'COMMUNITY_SET_MODERATORS';
export const COMMUNITY_SET_MODERATORS_LOADING = 'COMMUNITY_SET_MODERATORS_LOADING';
export const COMMUNITY_SET_MODERATORS_ERROR = 'COMMUNITY_SET_MODERATORS_ERROR';
export const COMMUNITY_SET_MODERATORS_DEFAULT = 'COMMUNITY_SET_MODERATORS_DEFAULT';
export const COMMUNITY_SET_CURRENT_MODERATOR = 'COMMUNITY_SET_CURRENT_MODERATOR';
export const COMMUNITY_PURGE_CURRENT_MODERATOR = 'COMMUNITY_PURGE_CURRENT_MODERATOR';
export const COMMUNITY_ADD_MODERATOR = 'COMMUNITY_ADD_MODERATOR';
export const COMMUNITY_DELETE_MODERATOR = 'COMMUNITY_DELETE_MODERATOR';
export const COMMUNITY_UPDATE_MODERATOR = 'COMMUNITY_UPDATE_MODERATOR';

const initialState = {
  moderators: {
    state: [],
    loading: false,
    error: null,
  },
  currentModerator: {
    id: null,
    moderatorData:{}
  }
}

const state = initialState;

const getters = {
  communityModerators(state) {
    return state.moderators.state;
  },
  communityCurrentModeratorId() {
    return state.currentModerator.id;
  },
  communityCurrentModerator() {
    return state.currentModerator.moderatorData;
  }
}

const actions = {
  [COMMUNITY_ADD_MODERATOR] (context, payload) {
    return ApiService
      .post('/client/communities/' + context.getters.communityId + '/moderator', payload.data)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_UPDATE_MODERATOR] (context, payload) {
    return ApiService
      .put('/client/communities/' + context.getters.communityId + '/moderator/'+ payload.id, payload.data)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_FETCH_MODERATOR] (context, payload) {
    return ApiService
      .get('client/communities/' + context.getters.communityId + '/moderator/'+payload.moderatorId)
      .then(({data}) => {
        //context.commit(COMMUNITY_PURGE_CURRENT_MODERATOR);
        context.commit(COMMUNITY_SET_CURRENT_MODERATOR, data);
        return data;
      })
      .catch(({response}) => {
        return response;
      });
  },
  [COMMUNITY_DELETE_MODERATOR] (context, payload) {
    return ApiService
      .delete('/client/communities/' + context.getters.communityId + '/moderator/'+payload.moderatorId)
      .then(({data}) => {
        context.commit(COMMUNITY_PURGE_CURRENT_MODERATOR, data);
        return data;
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_FETCH_MODERATORS] (context, payload) {
    context.commit(COMMUNITY_SET_MODERATORS_LOADING);
    return ApiService
      .get('/client/communities/' + context.getters.communityId + '/moderator')
      .then(({data}) => {
        let moderators = data
        context.commit(COMMUNITY_SET_MODERATORS, moderators);
        return data;
      })
      .catch(({response}) => {
        context.commit(COMMUNITY_SET_MODERATORS_ERROR, response);
        return response;
      });
  }
}
const mutations = {
  [COMMUNITY_SET_MODERATORS] (state, moderators) {
    state.moderators.state = moderators;
    state.moderators.loading = false;
    state.moderators.error = null;
  },
  [COMMUNITY_SET_MODERATORS_LOADING] (state) {
    state.moderators.loading = true;
  },
  [COMMUNITY_SET_MODERATORS_ERROR] (state, error) {
    state.moderators.error = error;
    state.moderators.loading = false;
  },
  [COMMUNITY_SET_CURRENT_MODERATOR] (state, moderatorData) {
    state.currentModerator.id = moderatorData.identifier;
    state.currentModerator.moderatorData = moderatorData;
  },
  [COMMUNITY_PURGE_CURRENT_MODERATOR] (state) {
    state.currentModerator = initialState.currentModerator;
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
};
