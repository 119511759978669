import sha256 from 'crypto-js/sha256';
  export const loadCitiServiPixel = async function(email: any){
    if(process.env.NODE_ENV != 'production') return
    var btrid = ("; " + document.cookie).split("; _btrid=").pop()?.split(";").shift();
    var ref = encodeURIComponent(window.location.href);
    var extid = sha256(email);
    var dscript = document.createElement("script");
    dscript.async = true;
    if(extid != null) dscript.src = "https://dmp.citiservi.es/pxtrack.js?mode=2&p=5b1grfox&sid=7174&bt=" + btrid + "&ref=" + ref + "&id=" + extid;
    else dscript.src = "https://dmp.citiservi.es/pxtrack.js?mode=2&p=5b1grfox&sid=7174&bt=" + btrid + "&ref=" + ref;
    document.head.appendChild(dscript);
  }