import MiscProvincesModule from './provinces.module';

const initialState = {
  ...MiscProvincesModule.state,
};

const state = initialState;

const getters = {
  ...MiscProvincesModule.getters,
};

const actions = {
  ...MiscProvincesModule.actions,
};

const mutations = {
  ...MiscProvincesModule.mutations,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
