import ApiService from '@/services/api.service';
import {USER_PURGE} from '../user.module';

export const COMMUNITY_FETCH_INFO = 'COMMUNITY_FETCH_INFO';
export const COMMUNITY_UPDATE_INFO = 'COMMUNITY_UPDATE_INFO'
export const COMMUNITY_UPDATE_REDEEM_INFO = 'COMMUNITY_UPDATE_REDEEM_INFO';
export const COMMUNITY_FETCH_CONDITIONS = 'COMMUNITY_FETCH_CONDITIONS';
export const COMMUNITY_FETCH_STATUS = 'COMMUNITY_FETCH_STATUS';
export const COMMUNITY_SET_STATUS = 'COMMUNITY_SET_STATUS';
export const COMMUNITY_SEND_EMAIL = 'COMMUNITY_SEND_EMAIL';
export const COMMUNITY_SEND_NOTIFICATION = 'COMMUNITY_SEND_NOTIFICATION';
export const COMMUNITY_SET_INFO = 'COMMUNITY_SET_INFO';
export const COMMUNITY_SET_INFO_LOADING = 'COMMUNITY_SET_INFO_LOADING';
export const COMMUNITY_SET_INFO_ERROR = 'COMMUNITY_SET_INFO_ERROR';
export const UPDATE_REPLY_TO_EMAIL = 'UPDATE_REPLY_TO_EMAIL';
export const UPLOAD_IMG_TO_S3 = 'UPLOAD_IMG_TO_S3'

const initialState = {
  info: {
    state: {},
    loading: false,
    error: null,
  },
  communityStatus: {
    state:{},
    loading:false,
    error: null,
  },
};

const state = initialState;

const getters = {
  communityInfo(state) {
    return state.info.state;
  },

  communityMembersCount(state) {
    return state.info.state.testerNum;
  },
  communityStatus(state) {
    return state.communityStatus.state
  },
};

const actions = {

  [COMMUNITY_FETCH_INFO] (context) {
    context.commit(COMMUNITY_SET_INFO_LOADING);

    return ApiService
      .get('/' + (context.getters.isClient ? 'client' : 'tester') + '/communities/' + context.getters.communityId + '/welcome')
      .then(({data}) => {
        context.commit(COMMUNITY_SET_INFO, data)
        return data
      })
      .catch(({response}) => {
        if(response.data.payload == "CommunityOnline does not exist") context.commit(USER_PURGE);
        context.commit(COMMUNITY_SET_INFO_ERROR, response)
      });
  },
  [COMMUNITY_UPDATE_INFO] (context,payload) {
    context.commit(COMMUNITY_SET_INFO_LOADING);
    const {data} = payload
    return ApiService
      .put('/client/communities/' + context.getters.communityId + '/welcome',data)
      .then(({data}) => {
        context.commit(COMMUNITY_SET_INFO, data)
        return data
      })
      .catch(({response}) => {
        context.commit(COMMUNITY_SET_INFO_ERROR, response)
      });
  },
  [UPDATE_REPLY_TO_EMAIL] ( context, payload ) {
    console.log("payload:", payload.data)
    return ApiService
        .put('/client/communities/' + context.getters.communityId + '/replytoclientemail', {replyToClientEmail: payload.data})
        .then( ( { data } ) => {return data} )
        .catch( ( { response } ) => {return Promise.reject(response)} )
  },
  [COMMUNITY_UPDATE_REDEEM_INFO] (context,payload) {
    const {data} = payload
    return ApiService
      .put('/experienceLabs/community/' + context.getters.communityId + '/redeemInfo',data)
      .then(({data}) => {return data})
      .catch(({response}) => {return response});
  },
  [COMMUNITY_FETCH_CONDITIONS] (context,payload) {
    return ApiService
      .get('/experienceLabs/' + context.getters.communityId + '/conditions')
      .then(({data}) => {
        return data
      })
      .catch(({response}) => {
        return response
      });
  },
  [COMMUNITY_FETCH_STATUS] (context,payload) {
    return ApiService
      .get('/experienceLabs/client/communities/' + context.getters.communityId + '/satusData')
      .then(({data}) => {
        context.commit(COMMUNITY_SET_STATUS, data)
        return data
      })
      .catch(({response}) => {
        return response
      });
  },
  [COMMUNITY_SEND_EMAIL] (context, payload) {
    return ApiService
      .post('/experienceLabs/client/communities/' + context.getters.communityId + '/sendCustomEmail', payload.data )
      .then(({data}) => data)
      .catch(({response}) => response);
  },
  
  [COMMUNITY_SEND_NOTIFICATION]: async (context, payload) => {
    try {
      const {data} = await ApiService.post(
        `/experienceLabs/client/communities/${context.getters.communityId}/sendCustomNotification`,
        payload.data
      );
      return data;
    } catch (error) {
      console.error("Error sending notification:", error.response);
      return error.response;
    }
  },  

  [UPLOAD_IMG_TO_S3]: async (context, payload) =>{
    var formData = new FormData();
    formData.append("file", payload.file);
    formData.append("publicLogo", payload.publicLogo);
    const headers = {
      'Content-Type': 'multipart/form-data'
    }

    return ApiService
      .postFile('/experienceLabs/file', formData, headers)
      .then(({data}) => {
        return data;
      })
      .catch(({response})  => {
        return response;
      })
  }

};

const mutations = {
  [COMMUNITY_SET_INFO] (state, info) {
    state.info.state = info;
    state.info.loading = false;
    state.info.error = null;
  },
  [COMMUNITY_SET_STATUS] (state, status) {
    state.communityStatus.state = status;
    state.communityStatus.loading = false;
    state.communityStatus.error = null;
  },
  [COMMUNITY_SET_INFO_LOADING] (state) {
    state.info.loading = true;
  },

  [COMMUNITY_SET_INFO_ERROR] (state, error) {
    state.info.error = error;
    state.info.loading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
