import ApiService from '../services/api.service'
import { VueCookieNext } from 'vue-cookie-next'

const actions = {
  async EXAMPLE_ACTION_NAME(context:any, payload:any) {
    /* NECESSARY TO ADD AUTHTOKEN */
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    /* END NECESSARY TO ADD AUTHTOKEN */
    return ApiService
      // .get(path, body, headers)
      .get('example_get',null,headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
  },
  async NOTIFY_COMPLETION(context:any, payload:any) {
    /* NECESSARY TO ADD AUTHTOKEN */
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    /* END NECESSARY TO ADD AUTHTOKEN */
    return ApiService
      .post(payload.url,payload,headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
  },
  async STORE_TEST_RECORDINGS(context:any, payload:any) {

    var formData = new FormData();
    formData.append("file", payload.file);
    let headers = {
      onUploadProgress: payload.onUploadProgress,
      headers:{}
    }
    /* NECESSARY TO ADD AUTHTOKEN */
    if (isNaN(payload.testId)) {
      headers.headers = {
        'Content-Type': 'multipart/form-data',
        tesVisToken : ApiService.getConfig().testerVisitorToken()
      }
    }
    else {
      headers.headers = {
        'Content-Type': 'multipart/form-data',
        authToken : ApiService.getConfig().token()
      }
    }
    /* END NECESSARY TO ADD AUTHTOKEN */

    return ApiService
      .postFile('/uxVideo',formData,headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
  },
  async VALIDATE_DATA_COMPLETION(context:any, payload:any) {
    /* NECESSARY TO ADD AUTHTOKEN */
    let headers = {}
    if (isNaN(payload.testId)) {
      headers = {headers:{'tesVisToken':ApiService.getConfig().testerVisitorToken()}}
    }
    /* END NECESSARY TO ADD AUTHTOKEN */
    return ApiService
      // .get(path, body, headers)
      .get(payload.url,null,headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return response
      })
  },
}

export default{
  actions
}
