import { flatMap } from 'lodash';
import ApiService from '@/services/api.service';

export const COMMUNITY_FETCH_TAGS = 'COMMUNITY_FETCH_TAGS';
export const COMMUNITY_SET_TAGS = 'COMMUNITY_SET_TAGS';
export const COMMUNITY_SET_TAGS_LOADING = 'COMMUNITY_SET_TAGS_LOADING';
export const COMMUNITY_SET_TAGS_ERROR = 'COMMUNITY_SET_TAGS_ERROR';
export const COMMUNITY_SET_MEMBERS_TAG = 'COMMUNITY_SET_MEMBERS_TAG'

const initialState = {
  tags: {
    state: {},
    loading: false,
    error: null,
  },
  publicTags: {
    state: {},
    loading: false,
    error: null,
  },
};

const state = initialState;

const getters = {
  communityTags(state) {
    return state.tags.state != undefined ? state.tags.state : [];
  },
  communityPublicTags(state) {
    return state.publicTags.state != undefined ? state.publicTags.state : [];
  },
};

const actions = {
  [COMMUNITY_FETCH_TAGS] (context) {
    context.commit(COMMUNITY_SET_TAGS_LOADING);

    return ApiService
      .get('/experienceLabs/community/' + context.getters.communityId + '/tags')
      .then(({data}) => {
        context.commit(COMMUNITY_SET_TAGS, data);
        return data;
      })
      .catch(({response}) => {
        context.commit(COMMUNITY_SET_TAGS_ERROR, response);
      });
  },
  [COMMUNITY_SET_MEMBERS_TAG] (context, payload) {
    return ApiService
      .post('/experienceLabs/community/' + context.getters.communityId + '/addMemberTags', payload)
      .then(({data}) => {
        return data;
      })
      .catch(({response}) => {
        return response.data;
      });
  },
};

const mutations = {
  [COMMUNITY_SET_TAGS] (state, tags) {
    state.tags.state = tags.tags;
    state.publicTags.state = tags.publicTags;
    state.tags.loading = false;
    state.tags.error = null;
  },

  [COMMUNITY_SET_TAGS_LOADING] (state) {
    state.tags.loading = true;
  },

  [COMMUNITY_SET_TAGS_ERROR] (state, error) {
    state.tags.error = error;
    state.tags.loading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
