import { flatMap } from 'lodash';
import ApiService from '@/services/api.service';

export const COMMUNITY_FETCH_MEMBERS = 'COMMUNITY_FETCH_MEMBERS';
export const COMMUNITY_FETCH_MEMBERS_IDS = 'COMMUNITY_FETCH_MEMBERS_IDS';
export const COMMUNITY_SET_MEMBERS = 'COMMUNITY_SET_MEMBERS';
export const COMMUNITY_SET_MEMBERS_IDS = 'COMMUNITY_SET_MEMBERS_IDS';
export const COMMUNITY_SET_MEMBERS_LOADING = 'COMMUNITY_SET_MEMBERS_LOADING';
export const COMMUNITY_SET_MEMBERS_ERROR = 'COMMUNITY_SET_MEMBERS_ERROR';
export const COMMUNITY_FETCH_MEMBERS_EXCEL = 'COMMUNITY_FETCH_MEMBERS_EXCEL';
export const COMMUNITY_FETCH_DEMO_EXCEL = 'COMMUNITY_FETCH_DEMO_EXCEL';
export const COMMUNITY_FETCH_DEMO_EXCEL_MAIN_ADMIN = 'COMMUNITY_FETCH_DEMO_EXCEL_MAIN_ADMIN';
export const COMMUNITY_SET_MEMBERS_LABS = 'COMMUNITY_SET_MEMBERS_LABS';
export const COMMUNITY_DELETE_MEMBER_EMAIL = 'COMMUNITY_DELETE_MEMBER_EMAIL';
export const COMMUNITY_FETCH_ONLINE_USERS = 'COMMUNITY_FETCH_ONLINE_USERS';
export const COMMUNITY_SET_MEMBERS_ONLINE = 'COMMUNITY_SET_MEMBERS_ONLINE';
export const COMMUNITY_LOAD_PREVIEWED_USERS_EXCEL = 'COMMUNITY_LOAD_PREVIEWED_USERS_EXCEL';


export const FETCH_MEMBER_INFO = 'FETCH_MEMBER_INFO';
export const FETCH_MEMBER_INFO_BY_NICKNAME = 'FETCH_MEMBER_INFO_BY_NICKNAME';
export const DELETE_ALL_MEMBERS = 'DELETE_ALL_MEMBERS';
export const RESEND_INVITATION_MEMBERS = 'RESEND_INVITATION_MEMBERS';
export const ADD_MEMBER = 'ADD_MEMBER';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const COMMUNITY_FETCH_MEMBERS_INACTIVE = 'COMMUNITY_FETCH_MEMBERS_INACTIVE'
export const COMMUNITY_FETCH_MEMBERS_RANKING = 'COMMUNITY_FETCH_MEMBERS_RANKING'
export const COMMUNITY_FETCH_MEMBERS_RANKING_PARTICIPATIONS = 'COMMUNITY_FETCH_MEMBERS_RANKING_PARTICIPATIONS'
export const COMMUNITY_FETCH_MEMBERS_STATISTICS = 'COMMUNITY_FETCH_MEMBERS_STATISTICS'

export const COMMUNITY_FETCH_MEMBERS_JOB_STATUS = 'COMMUNITY_FETCH_MEMBERS_JOB_STATUS'

const initialState = {
  members: {
    state: {},
    loading: false,
    error: null,
    ids:[]
  },
  onlineUsers:{
    state:{
      testersOnline:[],
      testersOnlineCondensed:[],
      observerOnline:false,
      moderatorOnline:false,
    },
    loading:false,
    error:null,
  }
};

const state = initialState;

const getters = {
  communityMembers(state) {
    return state.members.state;
  },
  communityMemberIds(state){
    return state.members.ids
  },
  onlineUsers(state){
    return state.onlineUsers.state;
  }
};

const actions = {
  [COMMUNITY_FETCH_MEMBERS_IDS] (context, payload) {
      return ApiService
      .get('/experienceLabs/client/communities/' + context.getters.communityId + '/memberIds')
      .then(({data}) => {
          context.commit(COMMUNITY_SET_MEMBERS_IDS, data.communityMemberIds);
        return data;
      })
  },
  [COMMUNITY_FETCH_MEMBERS] (context, payload) {
    const { order, filter, publicFilter, name, nickname, identifier, email, notSetCommunityMembers, page, pageSize } = payload;

    let query = {
      order,
    };

    if(context.getters.isClient && filter) {
      query = {
        ...query,
        filter,
      };
    }
    if(context.getters.isClient && publicFilter) {
      query = {
        ...query,
        publicFilter,
      };
    }
    if(name){
      query = {
        ...query,
        name,
      };
    }

    if(nickname){
      query = {
        ...query,
        nickname,
      };
    }
    if(identifier){
      query = {
        ...query,
        identifier,
      };
    }
    if(email){
      query = {
        ...query,
        email,
      };
    }
    if(page){
      query = {
        ...query,
        page,
      };
    }
    if(pageSize){
      query = {
        ...query,
        pageSize,
      };
    }

    context.commit(COMMUNITY_SET_MEMBERS_LOADING);

    return ApiService
      .get('/' + (context.getters.isClient ? 'experienceLabs/client' : 'tester') + '/communities/' + context.getters.communityId + '/members', query)
      .then(({data}) => {
        if(!notSetCommunityMembers){
          context.commit(COMMUNITY_SET_MEMBERS, data.communityMembers);
          context.dispatch(COMMUNITY_FETCH_ONLINE_USERS)
        }
        return data;
      })
      .catch(({response}) => {
        context.commit(COMMUNITY_SET_MEMBERS_ERROR, response);
      });
  },
  [COMMUNITY_FETCH_MEMBERS_RANKING] (context, payload) {
    return ApiService
      .get('/experienceLabs/client/'+context.getters.communityId+'/membersRanking')
      .then(({data}) => {
        return data;
      })
      .catch(({response}) => {
        return response
      });
  },
  [COMMUNITY_FETCH_MEMBERS_RANKING_PARTICIPATIONS] (context, payload) {
    return ApiService
      .get('/experienceLabs/client/'+context.getters.communityId+'/membersRankingByParticipations')
      .then(({data}) => {
        return data;
      })
      .catch(({response}) => {
        return response
      });
  },
  [COMMUNITY_FETCH_MEMBERS_STATISTICS] (context, payload) {
    return ApiService
      .get('/experienceLabs/client/'+context.getters.communityId+'/statistics')
      .then(({data}) => {
        return data;
      })
      .catch(({response}) => {
        return response
      });
  },
  [COMMUNITY_FETCH_MEMBERS_EXCEL] (context, payload) {
    return ApiService
      .get('/experienceLabs/client/communities/' + context.getters.communityId + '/members/excel', null, {
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'authToken':ApiService.getConfig().token()
        },
        responseType: 'blob',
      })
      .then(({data}) => data)
      .catch(({response}) => response);
  },
  [COMMUNITY_FETCH_DEMO_EXCEL_MAIN_ADMIN] (context, payload) {
    return ApiService
      .get('/experienceLabs/demoExcel/mainAdmin', null, {
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'authToken':ApiService.getConfig().token()
        },
        responseType: 'blob',
      })
      .then(({data}) => data)
      .catch(({response}) => response);
  },
  [COMMUNITY_FETCH_DEMO_EXCEL] (context, payload) {
    return ApiService
      .get('/experienceLabs/demoExcel', null, {
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'authToken':ApiService.getConfig().token()
        },
        responseType: 'blob',
      })
      .then(({data}) => data)
      .catch(({response}) => response);
  },
  [COMMUNITY_LOAD_PREVIEWED_USERS_EXCEL] (context, payload) {
    return ApiService
      .post('/experienceLabs/community/' + context.getters.communityId + '/job/' + payload.jobId + '/loadPreviewedExcel', payload);
  },
  [COMMUNITY_FETCH_MEMBERS_JOB_STATUS] (context, payload) {
    return ApiService
      .get('/experienceLabs/community/' + context.getters.communityId + '/job/'+payload.jobId+'/status');
  },

  [COMMUNITY_SET_MEMBERS_LABS] (context, payload) {
    return ApiService
      .post('/experienceLabs/client/communities/' + context.getters.communityId + '/customPoints', payload)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_DELETE_MEMBER_EMAIL] (context, payload) {
    return ApiService
      .post('/experienceLabs/client/communities/' + context.getters.communityId + '/deleteEmail', payload)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  [COMMUNITY_FETCH_ONLINE_USERS] (context,payload) {
    return ApiService
      .get('/experienceLabs/'+(context.getters.isClient ? 'client/communities/' : '') + context.getters.communityId + '/onlineUsersCondensed')
      .then(({data}) => {
        context.commit(COMMUNITY_SET_MEMBERS_ONLINE,data)
        return data
      })
      .catch(({response}) => {
        return response
      });
  },
  [COMMUNITY_FETCH_MEMBERS_INACTIVE] (context,payload) {
    const { order, filter, publicFilter, name, nickname, notSetCommunityMembers } = payload;

    let query = {
      order,
    };

    if(context.getters.isClient && filter) {
      query = {
        ...query,
        filter,
      };
    }
    if(context.getters.isClient && publicFilter) {
      query = {
        ...query,
        publicFilter,
      };
    }
    if(name){
      query = {
        ...query,
        name,
      };
    }

    if(nickname){
      query = {
        ...query,
        nickname,
      };
    }
    return ApiService
      .get('/experienceLabs/client/communities/'+context.getters.communityId+'/inactiveMembers', query)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => {
        return response
      });
  },
  [FETCH_MEMBER_INFO] (context,payload) {
    return ApiService
      .get('/experienceLabs/'+context.getters.communityId+'/user/'+payload.userId)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => {
        return response
      });
  },
  [FETCH_MEMBER_INFO_BY_NICKNAME] (context,payload) {
    return ApiService
      .get('/experienceLabs/'+context.getters.communityId+'/userByNickname/'+payload.nickname)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => {
        return response
      });
  },
  [ADD_MEMBER] (context,payload) {
    return ApiService
      .post('/experienceLabs/'+context.getters.communityId+'/user', payload.data)
  },
  [UPDATE_MEMBER] (context,payload) {
    return ApiService
      .put('/experienceLabs/'+context.getters.communityId+'/user/'+payload.userId, payload.data)
  },
  [DELETE_ALL_MEMBERS] (context,payload) {
    return ApiService
      .delete('/experienceLabs/'+context.getters.communityId+'/user',payload)
  },
  [RESEND_INVITATION_MEMBERS] (context,payload) {
    return ApiService
      .post('/experienceLabs/client/communities/'+context.getters.communityId+'/invitation',payload)
  },
};

const mutations = {

  memberOnline (state, o){
    o.member.online = o.value
  },
  [COMMUNITY_SET_MEMBERS_ONLINE] (state,data) {
    for(let m of state.members.state){
      m.online = data.testersOnlineCondensed.includes(m.identifier)
    }
    state.onlineUsers.state = data
  },
  [COMMUNITY_SET_MEMBERS] (state, members) {
    for(let m of members){
      m.online = false
    }
    state.members.state = members;
    state.members.loading = false;
    state.members.error = null;
  },
  [COMMUNITY_SET_MEMBERS_IDS] (state, memberIds){
    state.members.ids = memberIds
  },
  [COMMUNITY_SET_MEMBERS_LOADING] (state) {
    state.members.loading = true;
  },

  [COMMUNITY_SET_MEMBERS_ERROR] (state, error) {
    state.members.error = error;
    state.members.loading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
