import ApiService from '~/services/api.service'
import store from '@/store';

ApiService.setConfig( {
  baseURL: process.env.VUE_APP_API_URL,
  token: () => { return store.getters; },
  pepe: 'resuce'
} );

export default ApiService;
