import ApiService from '@/services/api.service';
import AdminApiService from '@/services/adminapi.service';

import {
  ROLE_USER,
  ROLE_MODERATOR,
  ROLE_OBSERVATOR,
  ROLE_ADMINISTRATOR,
  ROLE_ADMIN
} from '#/constants/user-roles.type';

export const USER_AUTH_LOGIN = 'USER_AUTH_LOGIN';
export const USER_AUTH_SIGNUP = 'USER_AUTH_SIGNUP';
export const USER_CLIENT_AUTH_LOGIN = 'USER_CLIENT_AUTH_LOGIN';
export const USER_CLIENT_FORCE_AUTH_LOGIN = 'USER_CLIENT_FORCE_AUTH_LOGIN';
export const USER_ADMIN_AUTH_LOGIN = 'USER_ADMIN_AUTH_LOGIN';
export const USER_AUTH_LOGOUT = 'USER_AUTH_LOGOUT';
export const USER_SET_AUTH = 'USER_SET_AUTH';
export const USER_SET_AUTH_DATA = 'USER_SET_AUTH_DATA';
export const USER_ADD_AUTH_DATA = 'USER_ADD_AUTH_DATA';
export const USER_ADMIN_SET_AUTH = 'USER_ADMIN_SET_AUTH';
export const USER_SET_AUTH_LOADING = 'USER_SET_AUTH_LOADING';
export const USER_SET_AUTH_ERROR = 'USER_SET_AUTH_ERROR';
export const USER_ADMIN_SET_AUTH_ERROR = 'USER_ADMIN_SET_AUTH_ERROR';
export const USER_REMOVE_AUTH_ERROR = 'USER_REMOVE_AUTH_ERROR';
export const USER_FETCH_PROFILE = 'USER_FETCH_PROFILE';
export const USER_SET_PROFILE = 'USER_SET_PROFILE';
export const USER_SET_PROFILE_LOADING = 'USER_SET_PROFILE_LOADING';
export const USER_SET_PROFILE_ERROR = 'USER_SET_PROFILE_ERROR';
export const USER_SET_CLIENT_DATA = 'USER_SET_CLIENT_DATA'
export const USER_PURGE = 'USER_PURGE';
export const USER_PUT_PROFILE_CHANGES = 'USER_PUT_PROFILE_CHANGES';
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
export const USER_CHANGE_NICKNAME = 'USER_CHANGE_NICKNAME';
export const USER_PASSWORD_RESET = 'USER_PASSWORD_RESET';
export const USER_PASSWORD_RESET_NEW = 'USER_PASSWORD_RESET_NEW';
export const USER_VERIFY_ACTIONCODE = 'USER_VERIFY_ACTIONCODE';
export const USER_GET_NICKNAME_AVAILABILITY = 'USER_GET_NICKNAME_AVAILABILITY';
export const USER_FETCH_HISTORICAL = 'USER_FETCH_HISTORICAL';
export const USER_REMOVE_ACCOUNT = 'USER_REMOVE_ACCOUNT';
export const CLIENT_FETCH_ROLE = 'CLIENT_FETCH_ROLE';
export const ADMIN_SAVE2FACODE = 'ADMIN_SAVE2FACODE'
export const COMMUNITY_DISABLE_2FA = 'COMMUNITY_DISABLE_2FA'
export const SEND_VALIDATION_CODE = 'SEND_VALIDATION_CODE'
export const RESEND_VALIDATION_CODE = 'RESEND_VALIDATION_CODE'
export const CLIENT_PASSWORD_RESET = 'CLIENT_PASSWORD_RESET'
export const CLIENT_PASSWORD_RESET_NEW = 'CLIENT_PASSWORD_RESET_NEW'
export const CLIENT_VERIFY_ACTIONCODE = 'CLIENT_VERIFY_ACTIONCODE'
const initialState = {
  auth: {
    state: {},
    loading: false,
    error: null,
  },
  profile: {
    state: {},
    loading: false,
    error: null,
    provinces: null
  },
  clientData:{}
};

const state = initialState;

const getters = {
  nextStep(state: any){
    return state.auth.state.nextStep
  },
  needs2FA(state: any){
    return state.auth.state.needs2FA
  },
  isAuthenticated(state: any) {
    return !!state.auth.state.authToken;
  },

  authToken(state: any) {
    return state.auth.state.authToken;
  },

  adminAuthToken(state: any) {
    return state.auth.state.adminAuthToken;
  },


  isClient(state: any) {
    return state.auth.state.role != ROLE_USER && (state.auth.state.role == ROLE_OBSERVATOR || state.auth.state.role == ROLE_ADMINISTRATOR || state.auth.state.role == ROLE_MODERATOR);
  },

  isUser(state: any) {
    return state.auth.state.role == ROLE_USER;
  },

  isModerator(state: any) {
    return state.auth.state.role == ROLE_MODERATOR || state.auth.state.role == ROLE_ADMINISTRATOR;
  },

  isCommunityAdmin(state: any) {
    return state.auth.state.role == ROLE_ADMINISTRATOR;
  },

  isCommunityMainAdmin(state: any) {
    return state.auth.state.role == ROLE_ADMINISTRATOR && state.auth.state.mainAdmin;
  },

  isObservator(state: any) {
    return state.auth.state.role == ROLE_OBSERVATOR;
  },

  isAdmin(state: any) {
    return !!state.auth.state.adminAuthToken;
  },

  authenticationError(state: any) {
    return state.auth.error;
  },

  userProfile(state: any) {
    return state.profile.state;
  },
  userIdentifier(state: any){
    return state.profile.state.id
  },

  loadingAuth(state: any) {
    return state.auth.loading;
  },
  clientData(state: any){
    return state.clientData
  },
  isLoggedFromSuite(state: any){
    return state.auth.state.fromSuite
  }
};

const actions = {
  [USER_AUTH_LOGIN] (context: any, payload: any) {
    const { loginData } = payload;
    let data = loginData
    data.origin = process.env.VUE_APP_TYPE

    context.commit(USER_SET_AUTH_LOADING);

    return ApiService
      .post('/experienceLabs/' + context.getters.origin + '/user/login', data)
      .then(({data}: any) => {
        context.commit(USER_SET_AUTH, {
          authToken: data.authToken,
          role: ROLE_USER,
          nextStep: data.nextStep,
          needs2FA: context.getters.theme.active2fa
        });
        
        return data;
      })
      .catch(({response}: any) => {
        context.commit(USER_SET_AUTH_ERROR, response);
        return response;
      });
  },

  [USER_AUTH_SIGNUP] (context: any, payload: any) {
    const { signupData } = payload;
    let data = signupData
    data.origin = process.env.VUE_APP_TYPE
    context.commit(USER_SET_AUTH_LOADING);

    return ApiService
      .post('/experienceLabs/' + context.getters.origin + '/register', signupData)
      .then(({data}: any) => {
        context.commit(USER_SET_AUTH, {
          authToken: data.authToken,
          role: ROLE_USER,
          nextStep: data.nextStep,
        });
        return data;
      })
      .catch(({response}: any) => {
        context.commit(USER_SET_AUTH_ERROR, response);
        return Promise.reject(response);
      });
  },
  [USER_CLIENT_FORCE_AUTH_LOGIN](context: any, payload: any) {
    context.commit(USER_SET_AUTH, {
      authToken: payload.authToken,
      role: payload.role,
      mainAdmin: payload.mainAdmin
    });
  },
  [USER_CLIENT_AUTH_LOGIN] (context: any, payload: any) {
    const { loginData } = payload;
    let data = loginData
    data.origin = process.env.VUE_APP_TYPE
    context.commit(USER_SET_AUTH_LOADING);

    return ApiService
      .post('/experienceLabs/' + context.getters.origin + '/client/login', data)
      .then(({data}: any) => {
        context.commit(USER_SET_AUTH, {
          authToken: data.authToken,
          role: data.rol,
          mainAdmin: data.mainAdmin,
          needs2FA: data.active2fa
        });
        return data;
      })
      .catch(({response}: any) => {
        context.commit(USER_SET_AUTH_ERROR, response);
        return response;
      });
  },

  [USER_ADMIN_AUTH_LOGIN] (context: any, payload: any) {
    const { loginData } = payload;
    let data = loginData
    data.origin = process.env.VUE_APP_TYPE
    context.commit(USER_SET_AUTH_LOADING);

    return AdminApiService
      .post('/admin/communities/login', data)
      .then(({data}: any) => {
        context.commit(USER_ADMIN_SET_AUTH, {
          adminAuthToken: data.authToken,
        });
        return data;
      })
      .catch(({response}: any) => {
        context.commit(USER_ADMIN_SET_AUTH_ERROR, response);
        return response;
      });
  },
  [ADMIN_SAVE2FACODE] (context: any,payload: any) {
    return AdminApiService
      .post('/admin/2facode', payload, {headers:{authtoken:context.getters.adminAuthToken}})
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any) => {
        return Promise.reject(response)
      });
  },
  [USER_AUTH_LOGOUT] (context: any) {
    return ApiService
      .post('/experienceLabs/' + context.getters.origin + '/user/logout')
      .then(({data}: any) => {
        context.commit(USER_PURGE);
      })
  },

  [USER_FETCH_PROFILE] (context: any, payload: any) {
    context.commit(USER_SET_PROFILE_LOADING);
    if(payload!= undefined && payload.complete){
      return ApiService
        .get(context.getters.isClient ? '/client' : '/experienceLabs/' + context.getters.origin + '/userData')
    }
    return ApiService
      .get(context.getters.isClient ? '/client' : '/experienceLabs/' + context.getters.origin + '/userData')
      .then(({data}: any) => {
        if(context.getters.isClient) {
          context.commit(USER_SET_CLIENT_DATA,data)
          context.commit(USER_SET_PROFILE, {
            name: data.companyName,
            surname: '',
            image: data.companyLogoUrl,
          });
        } else {
          if(data.province == undefined || data.province.elementId == undefined || data.province.id == undefined) data.province = {lovName: "provinces", elementId: 0, id: 0}
          context.commit(USER_SET_PROFILE, {
            image:    data.picture,
            nickname: data.nickname,
            name:     data.name,
            surname:  data.surname1,
            email:    data.email,
            gender:   data.gender,
            bornDate: data.bornDate,
            province: data.province,
            medals:   data.medals,
            id:       data.identifier,
            commentsNum: data.commentsNum,
            points: data.points,
            picture: data.picture,
            afterLoginComplete: data.afterLoginComplete
          });
        }

        return data;
      })
      .catch(({response}: any) => {
        context.commit(USER_SET_PROFILE_ERROR, response);
        return response;
      });
  },

  [USER_GET_NICKNAME_AVAILABILITY] ( context: any, payload: any ) {
    const { nickname }  = payload;
    const communityId   = context.getters.communityId;

    return ApiService
      .get( `/experienceLabs/${ communityId  }/nickname/${ nickname }/availability` )
      .then( ( { data }: any ) => {
        return data;
      })
      .catch( ( { response }: any ) => {
        return response;
      } );
  },

  [USER_PUT_PROFILE_CHANGES] ( context: any, payload: any ) {
    const { response } = payload;

    return ApiService
      .put( '/experienceLabs/userData', response );
  },

  [USER_CHANGE_PASSWORD] ( context: any, payload: any ) {
    const { response } = payload;

    return ApiService
      .put( '/experienceLabs/password', response );
  },

  [USER_CHANGE_NICKNAME] ( context: any, payload: any ) {
    return ApiService
      .put( '/experienceLabs/newNickname', payload );
  },

  [USER_PASSWORD_RESET] ( context: any, payload: any ) {
    const { email  } = payload;
    const { origin } = context.getters;

    return ApiService
      .post( `/experienceLabs/${ origin }/resetPassword`, { email } );
  },

  [USER_PASSWORD_RESET_NEW] ( context: any, payload: any ) {
    const { userID, password, codeAction } = payload;

    return ApiService
      .post( `/experienceLabs/${ userID }/resetNewPassword`, {
        codeAction: codeAction, password
      } );
  },

  [USER_VERIFY_ACTIONCODE] ( context: any, payload: any ) {
    const { userID, codeAction } = payload;

    return ApiService
      .post( `/experienceLabs/${ userID }/checkResetCode`, { codeAction } );
  },

  [CLIENT_PASSWORD_RESET] ( context: any, payload: any ) {
    const { email  } = payload;
    const { origin } = context.getters;

    return ApiService
      .post( `/experienceLabs/client/${ origin }/resetPassword`, { email } );
  },

  [CLIENT_PASSWORD_RESET_NEW] ( context: any, payload: any ) {
    const { userID, password, codeAction } = payload;

    return ApiService
      .post( `/experienceLabs/client/${ userID }/resetNewPassword`, {
        codeAction: codeAction, password
      } );
  },

  [CLIENT_VERIFY_ACTIONCODE] ( context: any, payload: any ) {
    const { userID, codeAction } = payload;

    return ApiService
      .post( `/experienceLabs/client/${ userID }/checkResetCode`, { codeAction } );
  },

  [USER_ADD_AUTH_DATA] ( context: any, payload: any ) {
    context.commit(USER_SET_AUTH_DATA, payload);
    return payload
  },

  [USER_FETCH_HISTORICAL] (context: any, payload: any) {
    const { data } = payload;

    return ApiService
      .post('/experienceLabs/'+context.getters.communityId+'/transactionsSearch?page='+data.page+'&pageSize='+data.pageSize)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any) => {
        return response;
      });
  },
  [USER_REMOVE_ACCOUNT] (context: any, payload: any) {
    return ApiService
      .delete('/experienceLabs/'+context.getters.communityId+'/userAccount')
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any) => {
        return response;
      });
  },
  [CLIENT_FETCH_ROLE] (context: any, payload: any) {
    return ApiService
      .get('/experienceLabs/' + context.getters.origin + '/client/roleData')
      .then(({data}: any) => {
        context.commit(USER_SET_AUTH_DATA, {
          role: data.rol,
          mainAdmin: data.mainAdmin
        });
        return data;
      })
      .catch(({response}: any) => {
        context.commit(USER_SET_AUTH_ERROR, response);
        return response;
      });
  },
  [SEND_VALIDATION_CODE] (context: any,payload: any) {
    const isClient = context.getters.isClient
    return AdminApiService
      .post('/experienceLabs/' + context.getters.origin + (isClient ? '/client' : '/user') + '/2facode', payload)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any) => {
        return Promise.reject(response)
      });
  },
  [RESEND_VALIDATION_CODE] (context: any,payload: any) {
    const isClient = context.getters.isClient
    return AdminApiService
      .post('/experienceLabs/' + context.getters.origin + (isClient ? '/client' : '/user') + '/resend2facode')
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any) => {
        return Promise.reject(response)
      });
  },
};

const mutations = {
  [USER_SET_AUTH] (state: any, auth: any) {
    state.auth.state = auth;
    state.auth.error = null;
    state.auth.loading = false;
  },

  [USER_SET_AUTH_DATA] (state: any, data: any) {
    if(data.authToken) state.auth.state.authToken = data.authToken;
    if(data.role) state.auth.state.role = data.role;
    if(data.mainAdmin) state.auth.state.mainAdmin = data.mainAdmin;
    if(data.fromSuite) state.auth.state.fromSuite = data.fromSuite;
  },

  [USER_SET_AUTH_LOADING] (state: any) {
    state.auth.loading = true;
    state.auth.error = null;
  },

  [USER_SET_AUTH_ERROR] (state: any, error: any) {
    state.auth.error = error;
    state.auth.loading = false;
  },

  [USER_REMOVE_AUTH_ERROR] (state: any) {
    state.auth.error = null;
    state.auth.loading = false;
  },

  [USER_SET_PROFILE] (state: any, profile: any) {
    state.profile.state = profile;
  },

  [USER_SET_PROFILE_LOADING] (state: any) {
    state.auth.loading = true;
  },

  [USER_SET_PROFILE_ERROR] (state: any, error: any) {
    state.auth.error = error;
    state.auth.loading = false;
  },

  [USER_SET_CLIENT_DATA] (state: any, data: any){
    state.clientData = data
  },

  [USER_PURGE] (state: any) {
    state.auth.state = initialState.auth.state;
    state.auth.loading = false;
    state.profile.state = initialState.profile.state;
  },

  [USER_ADMIN_SET_AUTH] (state: any, auth: any) {
    state.auth.state = auth;
    state.auth.error = null;
    state.auth.loading = false;
  },

  [USER_ADMIN_SET_AUTH_ERROR] (state: any, error: any) {
    state.auth.error = error;
    state.auth.loading = false;
  },
  [COMMUNITY_DISABLE_2FA] (state: any, data: any){
    state.auth.state.needs2FA = false
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
